//@ts-ignore
import { datadogRum } from '@datadog/browser-rum';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { ReactNode } from 'react';
import Badge from 'src/components/Badge';
import Tooltip from 'src/components/Tooltip';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  CurrencyValueFlat,
  CurrencyValuePercent,
  CurrencyValueType,
  DerivedValue,
  isQuotePriceEqual,
  QuotePrice,
  ZERO_PERCENT,
} from '../../types_common/price';
import {
  AlpacaPricingFlow,
  AlpacaProduct,
  AlpacaProductPrices,
  AlpacaTFxPricingInformation,
  AlpacaTreasuryFxProduct,
  isAlpacaTFXProduct,
} from '../alpaca_types';
import {
  computeDerivedAggregationsForProducts,
  formatCurrencyValue,
  getAverageCurrencyValue,
  roundQuotePrice,
} from '../alpaca_utils';
import AlpacaAddCurrencyToBucketButton from './AlpacaAddCurrencyToBucketButton';
import { QuotePriceEditable } from './AlpacaQuotePriceEditable';
import { QuoteTableBodyEmptyState } from './AlpacaQuoteTableEmptyState';
import { VolumeEditable } from './AlpacaVolumeEditable';
import DerivedCurrencyValue from './DerivedCurrencyValue';

type SubCategory = 'bucket' | 'pair' | 'single_buy';

type PriceAndDetails = {
  price: CurrencyValuePercent;
  components: { price: CurrencyValuePercent; name: string }[] | null;
};

function getStickerPriceDetails(
  product: AlpacaTreasuryFxProduct,
  productInfos: AlpacaProductPrices,
  pricingFlow: AlpacaPricingFlow,
): PriceAndDetails {
  const currentPricingCurves = pricingFlow.currentPricingCurves;
  const quoteCurrency = pricingFlow.additionalData.quoteCurrency;
  switch (product.subCategory) {
    case 'bucket': {
      const components = product.currencyIds.map((currencyId) => {
        const pricingInfo = currentPricingCurves[currencyId]
          .pricingInformation as AlpacaTFxPricingInformation;
        const currency = productInfos[currencyId];
        return { price: pricingInfo.listPrice, name: currency.name };
      });
      const avgPrice = getAverageCurrencyValue(
        components.map((c) => c.price),
        quoteCurrency,
        pricingFlow,
      );
      if (avgPrice) {
        if (avgPrice.type !== CurrencyValueType.PERCENT) {
          datadogRum.addError(
            `Expected TFX products to all have percent list prices ${avgPrice} ${product.name}`,
          );
        }
        return {
          components,
          price: avgPrice as CurrencyValuePercent,
        };
      } else {
        datadogRum.addError(
          `computing mean price of an empty bucket ${components}`,
        );
        return {
          components,
          price: ZERO_PERCENT,
        };
      }
    }
    case 'pair': {
      const pricingInfo =
        currentPricingCurves[product.buyCurrencyId].pricingInformation;
      const listPrice = pricingInfo?.listPrice ?? ZERO_PERCENT;
      if (listPrice.type !== CurrencyValueType.PERCENT) {
        datadogRum.addError(
          `Expected TFX products to all have percent list prices ${listPrice} ${product.name}`,
        );
      }
      return { price: listPrice as CurrencyValuePercent, components: null };
    }
    case 'single_buy': {
      const pricingInfo =
        currentPricingCurves[product.currencyId].pricingInformation;
      const listPrice = pricingInfo?.listPrice ?? ZERO_PERCENT;
      if (listPrice.type !== CurrencyValueType.PERCENT) {
        datadogRum.addError(
          `Expected TFX products to all have percent list prices ${listPrice} ${product.name}`,
        );
      }
      return { price: listPrice as CurrencyValuePercent, components: null };
    }
    default:
      const typecheck: never = product;
      datadogRum.addError(`got invalid product subcategory ${typecheck}`);
      return { price: ZERO_PERCENT, components: null };
  }
}

// ##AlpacaTreasuryFxProductCost
// keep these in sync!
function getCost(
  product: AlpacaTreasuryFxProduct,
  productInfos: AlpacaProductPrices,
  pricingFlow: AlpacaPricingFlow,
): PriceAndDetails {
  const currentPricingCurves = pricingFlow.currentPricingCurves;
  const quoteCurrency = pricingFlow.additionalData.quoteCurrency;
  switch (product.subCategory) {
    case 'bucket': {
      const components = product.currencyIds.map((currencyId) => {
        const currency = productInfos[currencyId];
        const pricingInfo = currentPricingCurves[currencyId]
          .pricingInformation as AlpacaTFxPricingInformation;
        return { price: pricingInfo.cost, name: currency.name };
      });
      const avgCost = getAverageCurrencyValue(
        components.map((c) => c.price),
        quoteCurrency,
        pricingFlow,
      );
      if (avgCost) {
        if (avgCost.type !== CurrencyValueType.PERCENT) {
          datadogRum.addError(
            `Expected TFX products to all have percent costs ${avgCost} ${product.name}`,
          );
        }
        return {
          components,
          price: avgCost as CurrencyValuePercent,
        };
      } else {
        datadogRum.addError(
          `computing mean cost of an empty bucket ${product.name} ${components}`,
        );
        return { components, price: ZERO_PERCENT };
      }
    }
    case 'pair': {
      const pricingInfo =
        currentPricingCurves[product.buyCurrencyId].pricingInformation;
      const cost = pricingInfo?.cost ?? ZERO_PERCENT;
      if (cost.type !== CurrencyValueType.PERCENT) {
        datadogRum.addError(
          `Expected TFX products to all have percent costs ${cost} ${product.name}`,
        );
      }
      return {
        price: cost as CurrencyValuePercent,
        components: null,
      };
    }
    case 'single_buy': {
      const pricingInfo =
        currentPricingCurves[product.currencyId].pricingInformation;
      const cost = pricingInfo?.cost ?? ZERO_PERCENT;
      if (cost.type !== CurrencyValueType.PERCENT) {
        datadogRum.addError(
          `Expected TFX products to all have percent costs ${cost} ${product.name}`,
        );
      }
      return {
        price: cost as CurrencyValuePercent,
        components: null,
      };
    }
    default:
      const typecheck: never = product;
      datadogRum.addError(`got invalid product subcategory ${typecheck}`);
      return { price: ZERO_PERCENT, components: null };
  }
}

type ProductNameProps = {
  product: AlpacaTreasuryFxProduct;
  productInfos: AlpacaProductPrices;
};
function ProductName(props: ProductNameProps) {
  const { product, productInfos } = props;
  switch (product.subCategory) {
    case 'bucket':
      return (
        <div className="flex flex-col gap-2">
          <span>{product.name}</span>
          <div className="flex flex-row gap-1 items-center flex-wrap">
            {product.currencyIds
              .sort((a, b) => {
                const currencyA = productInfos[a];
                const currencyB = productInfos[b];
                if (currencyA && currencyB) {
                  return currencyA.name.localeCompare(currencyB.name);
                } else {
                  datadogRum.addError(
                    `could not find id in pricing sheet ${a} ${b}`,
                  );
                  return a.localeCompare(b);
                }
              })
              .map((currencyId) => {
                const currency = productInfos[currencyId];
                return (
                  <span
                    key={currencyId}
                    className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 h-fit"
                  >
                    {currency.name}
                  </span>
                );
              })}
            <AlpacaAddCurrencyToBucketButton product={product} />
          </div>
        </div>
      );
    case 'pair':
      // if text contains '->' then split it and return the two parts with an arrow in between
      const splitText = product.name.split('->');
      return (
        <span className="flex flex-row gap-2 items-center">
          {splitText[0]}
          <ArrowRightIcon className="h-4 w-4" />
          {splitText[1]}
        </span>
      );
    case 'single_buy':
      return <span>{product.name}</span>;
    default:
      const typecheck: never = product;
      datadogRum.addError(`got invalid product subcategory ${typecheck}`);
      return typecheck;
  }
}

type RowProps = {
  product: AlpacaTreasuryFxProduct;
};
function Row(props: RowProps) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const { product } = props;

  const productInfos =
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo;
  const handleDelete = () => {
    // handle delete action here
    const newProducts: AlpacaProduct[] =
      pricingFlow.products?.filter((p) => {
        return p.id !== product.id;
      }) ?? [];

    updateFlow(
      {
        ...pricingFlow,
        products: newProducts,
      },
      false,
    );
  };

  function updateQuotePrice(newQuotePrice: QuotePrice | null) {
    if (newQuotePrice && isQuotePriceEqual(newQuotePrice, quotePrice)) {
      // No change
      return;
    }

    const newProducts = (pricingFlow.products ?? []).map((p) => {
      if (p.id === product.id) {
        return {
          ...p,
          quotePrice: roundQuotePrice(
            newQuotePrice ?? stickerPriceWithDetails.price,
          ),
        };
      } else {
        return p;
      }
    });
    updateFlow(
      {
        ...pricingFlow,
        // @ts-ignore
        products: newProducts,
      },
      false,
    );
  }
  const stickerPriceWithDetails = getStickerPriceDetails(
    product,
    productInfos,
    pricingFlow,
  );
  const quotePrice = product.quotePrice ?? stickerPriceWithDetails.price;
  const cost = getCost(product, productInfos, pricingFlow);
  const name = <ProductName product={product} productInfos={productInfos} />;
  function updateTransactionCount(transactionCount: number | undefined) {
    if (transactionCount === product.transactionCount) {
      // No change
      return;
    }

    const newProducts = (pricingFlow.products ?? []).map((p) => {
      if (p.id === product.id) {
        return {
          ...p,
          transactionCount: transactionCount,
        };
      } else {
        return p;
      }
    });

    updateFlow(
      {
        ...pricingFlow,
        products: newProducts,
      },
      false,
    );
  }
  function updateVolume(volume: number) {
    if (volume === product.volume) {
      // No change
      return;
    }

    const newProducts = (pricingFlow.products ?? []).map((p) => {
      if (p.id === product.id) {
        return {
          ...p,
          volume: volume,
        };
      } else {
        return p;
      }
    });

    updateFlow(
      {
        ...pricingFlow,
        // @ts-ignore
        products: newProducts,
      },
      false,
    );
  }

  return (
    <tr>
      {/* Product */}
      <td className="relative min-w-[172px] px-6 py-4 border-t">
        <div className="flex flex-col gap-1">
          <span className="min-h-6 text-sm font-medium text-gray-900">
            {name}
          </span>
        </div>
      </td>

      {/** Monthly est volume */}
      <td className="overflow-show h-full w-full p-0 align-top border-t">
        <VolumeEditable
          volume={product.volume}
          updateVolume={updateVolume}
          transactionCount={product.transactionCount}
          updateTransactionCount={updateTransactionCount}
          quotePrice={quotePrice}
          quoteCurrency={pricingFlow.additionalData.quoteCurrency}
          cost={cost.price}
        />
      </td>
      {/* Quote price */}
      <td className="whitespace-nowrap overflow-show h-full w-full p-0 align-top border-t">
        <QuotePriceEditable
          quotePrice={quotePrice}
          updateQuotePrice={updateQuotePrice}
          validPriceTypes={[CurrencyValueType.PERCENT]}
          quoteCurrency={pricingFlow.additionalData.quoteCurrency}
          stickerPrice={stickerPriceWithDetails.price}
          cost={cost.price}
          productName={product.name}
          validTierMinimumTypes={[CurrencyValueType.FLAT]}
        />
      </td>
      {/* Sticker price */}
      <td className="has-tooltip whitespace-nowrap px-6 py-4 text-xs font-medium border-t content-start">
        <button
          className=""
          title="Set quote price to sticker price"
          onClick={() => {
            updateQuotePrice(stickerPriceWithDetails.price);
          }}
          disabled={!editMode}
        >
          <Badge color="green">
            {formatCurrencyValue(stickerPriceWithDetails.price)}
          </Badge>
        </button>
        {isNil(stickerPriceWithDetails.components) ? null : (
          <div className="flex-col tooltip z-20 ml-16 -mt-6 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
            {stickerPriceWithDetails.components.map((c) => (
              <div key={c.name}>
                {c.name}: {c.price.value}%
              </div>
            ))}
          </div>
        )}
      </td>
      {/* Cost */}
      <td className=" has-tooltip whitespace-nowrap px-6 py-4 text-xs font-medium text-orange-700 border-t  content-start">
        <button
          className=""
          title="Set quote price to cost"
          onClick={() => {
            updateQuotePrice(cost.price);
          }}
          disabled={!editMode}
        >
          <Badge color="orange">{formatCurrencyValue(cost.price)}</Badge>
        </button>
        {isNil(cost.components) ? null : (
          <div className="tooltip z-20 ml-16 -mt-6 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
            {cost.components.map((c) => (
              <div key={c.name}>
                {c.name}: {c.price.value}%
              </div>
            ))}
          </div>
        )}
      </td>
      {/* Monthly gross profit */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500 border-t  content-start">
        <DerivedCurrencyValue
          value={product.derivedAggregations?.grossProfit ?? null}
        />
      </td>
      {/* Margin */}
      <td className="px-6 py-4 text-sm  text-gray-500 2xl:table-cell border-t  content-start">
        <DerivedCurrencyValue
          value={product.derivedAggregations?.profitMargin ?? null}
        />
      </td>
      {/* Take Rate */}
      <td className="px-6 py-4 text-sm  text-gray-500 2xl:table-cell border-t  content-start">
        <DerivedCurrencyValue
          value={product.derivedAggregations?.takeRate ?? null}
        />
      </td>
      {/* Trash icon */}
      <td className="border-t  content-start">
        <button
          className={classNames(
            'transition-opacity duration-200 ease-in-out hover:text-gray-500 px-2 py-4',
            !editMode && 'opacity-0',
          )}
          onClick={handleDelete}
          disabled={!editMode}
        >
          <TrashIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </td>
    </tr>
  );
}

type BodyProps = {
  products: AlpacaTreasuryFxProduct[];
};
function Body(props: BodyProps) {
  const { products } = props;
  if (products.length <= 0) {
    return (
      <tbody>
        <QuoteTableBodyEmptyState />
      </tbody>
    );
  }
  return (
    <tbody>
      {products.map((product, idx) => {
        return <Row key={`${product.id}-${idx}`} product={product} />;
      })}
    </tbody>
  );
}

type FooterProps = {
  monthlyGrossProfit: DerivedValue<CurrencyValueFlat> | null;
  button: ReactNode;
};
function Footer(props: FooterProps) {
  const { monthlyGrossProfit, button } = props;

  return (
    <tfoot>
      <tr>
        <th
          colSpan={6}
          className="border-t bg-gray-50 whitespace-nowrap rounded-bl-xl bg-slate-50 px-6 py-2.5"
        >
          {button}
        </th>
        <th
          scope="col"
          colSpan={1}
          className="border-t bg-gray-50 hidden bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Gross Profit
        </th>
        <th
          scope="col"
          className="border-t bg-gray-50 hidden bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          <DerivedCurrencyValue value={monthlyGrossProfit} />
        </th>
        <th
          scope="col"
          className="border-t bg-gray-50 hidden rounded-br-xl bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {/* Trash icon */}
        </th>
      </tr>
    </tfoot>
  );
}

type HeaderProps = { title: string };
function Header(props: HeaderProps) {
  const { title } = props;
  return (
    <thead>
      <tr>
        <th
          scope="col"
          className="sticky top-0 z-10 w-full rounded-t-xl bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
        >
          {title}
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Monthly est. volume
        </th>
        <th
          scope="col"
          className=" min-w-[140px] sticky top-0 z-10 hidden bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Quote price
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Sticker price
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Cost
        </th>
        <Tooltip
          as="th"
          scope="col"
          className="sticky top-0 z-10 hidden bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap has-tooltip"
          location="TOP"
          text="Monthly gross revenue - monthly cost"
        >
          Monthly gross profit
        </Tooltip>
        <Tooltip
          as="th"
          scope="col"
          className="sticky top-0 z-10 hidden bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap has-tooltip"
          location="TOP"
          text="Monthly gross profit / monthly revenue"
        >
          Margin
        </Tooltip>
        <Tooltip
          as="th"
          scope="col"
          className="sticky top-0 z-10 hidden bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap has-tooltip"
          location="LEFT"
          text="Monthly gross profit / monthly est. volume"
        >
          Net take rate
        </Tooltip>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden rounded-t-xl bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {/* Trash icon */}
        </th>
      </tr>
    </thead>
  );
}

type AlpacaFxTableProps = {
  title: string;
  subCategories: SubCategory[];
  button: ReactNode;
};
export default function AlpacaFxTable(props: AlpacaFxTableProps) {
  const { title, button, subCategories } = props;
  const { pricingFlow } = usePricingFlowContext<AlpacaPricingFlow>();

  const allProducts = pricingFlow.products ?? [];
  const fxProducts = allProducts.filter(isAlpacaTFXProduct);
  const productsToShow = fxProducts.filter((p) =>
    subCategories.includes(p.subCategory),
  );

  const monthlyGrossProfit = computeDerivedAggregationsForProducts(
    productsToShow,
    pricingFlow,
    pricingFlow.additionalData.quoteCurrency,
  ).grossProfit;
  return (
    <div className="rounded-xl border border-gray-200 bg-white">
      <table className="min-w-full border-separate border-spacing-0">
        <Header title={title} />
        <Body products={productsToShow} />
        <Footer monthlyGrossProfit={monthlyGrossProfit} button={button} />
      </table>
    </div>
  );
}
