import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import backgroundLines from 'src/images/background-lines.svg';
import fadedCircleBg from 'src/images/faded-circle-bg.svg';
import logoDealopsTarget from 'src/images/logos/dealops-target.svg';
import { getOpportunitySFDCUrl } from '../../Penguin/Components/OpportunityOverviewBar';
import { usePricingFlowContext } from '../../PricingFlow';
import { AlpacaPricingFlow, getDefaultAdditionalData } from '../alpaca_types';
import { getOpportunityOverviewFields } from '../Components/AlpacaOpportunitySidebar';

type AlpacaStep1NotStartedProps = {};

const AlpacaStep1NotStarted = (props: AlpacaStep1NotStartedProps) => {
  const { pricingFlow, updateFlow, editMode, setStage } =
    usePricingFlowContext<AlpacaPricingFlow>();

  const startPricingFlow = async () => {
    if (!editMode) {
      setStage({
        stage: null,
        customStage: 'Quote Inputs',
      });
      return;
    }
    const additionalData =
      pricingFlow.additionalData ??
      (await getDefaultAdditionalData(pricingFlow));
    updateFlow({
      ...pricingFlow,
      additionalData: {
        ...additionalData,
        customStage: 'Quote Inputs',
      },
    });
  };

  const fields = getOpportunityOverviewFields(pricingFlow.opportunityData);

  return (
    <div
      className="relative flex flex-col items-center justify-center bg-repeat-x"
      style={{ backgroundImage: `url(${backgroundLines})` }}
    >
      {/* Dealops target logo */}
      <div className="mt-20 h-24 w-24">
        <img
          className="absolute h-24 w-24"
          src={fadedCircleBg}
          alt="faded circle"
        />
        <div className="absolute ml-5 mt-5 flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
          <img className="h-7 w-7" src={logoDealopsTarget} alt="Dealops" />
        </div>
      </div>

      {/** Heading */}
      <h1 className="mx-auto max-w-7xl px-4 pt-6 text-center text-2xl font-semibold sm:px-6 lg:px-8">
        Pricing calculator
      </h1>
      <p className="text-l mx-auto max-w-7xl px-4 pt-2 text-center text-gray-700 sm:px-6 lg:px-8">
        Let's work on pricing for this opportunity!
      </p>

      {/** Opportunity details */}
      <div className="mb-20 mt-10 w-[400px] rounded-2xl border border-gray-200 bg-white">
        <div className="flex flex-col items-center justify-center gap-1 px-4 py-4">
          <span className="text-xs text-gray-500">
            Opportunity #{pricingFlow.sfdcOpportunityId}
          </span>
          <span className="text-2xl text-gray-900">
            {pricingFlow.sfdcOpportunityName}
          </span>
        </div>
        <div className="bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
          DETAILS
        </div>

        <div className="flex flex-col gap-2.5 px-4 py-4">
          {fields.map((field) => (
            <div
              key={field.name}
              className="flex w-full flex-row justify-between"
            >
              <span className="text-sm text-gray-500">{field.name}</span>
              <span className="text-sm font-medium text-gray-900">
                {field.value}
              </span>
            </div>
          ))}
        </div>

        {/** Buttons */}
        <div className="flex items-center gap-12 border-t border-gray-200 px-4 py-4">
          <button
            onClick={() =>
              window.open(
                getOpportunitySFDCUrl(pricingFlow),
                '_blank',
                'noopener,noreferrer',
              )
            }
            className="flex w-full flex-row items-center gap-2 rounded-lg px-3 py-2 text-sm font-bold text-fuchsia-900 hover:text-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
            role="link"
            tabIndex={0}
          >
            See in SFDC
            <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            onClick={startPricingFlow}
            className="w-full rounded-lg bg-fuchsia-900 px-3 py-2 text-sm font-bold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          >
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlpacaStep1NotStarted;
