import { PricingFlowCommon } from './types';

export default function OpportunityOverviewBar(props: {
  opportunityData: PricingFlowCommon['opportunityData'];
  stretchWidth?: boolean;
  loading?: boolean;
  type: PricingFlowCommon['type'];
}) {
  return null;
  // const { loading, type, opportunityData } = props;

  // let fields: Array<{
  //   icon: React.FC<React.HTMLAttributes<SVGSVGElement>>;
  //   name: string;
  //   value: React.ReactNode;
  // }> = [];

  // switch (type) {
  //   case PricingFlowType.DEALOPS:
  //     const dealopsOpportunitData: DealopsOpportunityData =
  //       opportunityData as DealopsOpportunityData;
  //     let dealopsBadgeColor: BadgeColor = 'gray';
  //     switch (dealopsOpportunitData.Opportunity_StageName) {
  //       case 'Closed Won':
  //         dealopsBadgeColor = 'green';
  //         break;
  //       case 'Closed Lost':
  //         dealopsBadgeColor = 'red';
  //         break;
  //     }

  //     fields = [
  //       {
  //         icon: UserIcon,
  //         name: 'Owner',
  //         value: dealopsOpportunitData.User_Name ?? '–',
  //       },
  //       {
  //         icon: TagIcon,
  //         name: 'Industry',
  //         value: dealopsOpportunitData.Account_Industry ?? '–',
  //       },
  //       {
  //         icon: GlobeAltIcon,
  //         name: 'Country',
  //         value: dealopsOpportunitData.Account_BillingCountry ?? '–',
  //       },
  //       {
  //         icon: ClockIcon,
  //         name: 'Stage',
  //         value: dealopsOpportunitData.Opportunity_StageName ? (
  //           <Badge color={dealopsBadgeColor}>
  //             {dealopsOpportunitData.Opportunity_StageName}
  //           </Badge>
  //         ) : (
  //           '–'
  //         ),
  //       },
  //       {
  //         icon: ArrowTrendingUpIcon,
  //         name: 'Likelihood to close',
  //         value: dealopsOpportunitData.Opportunity_Probability
  //           ? dealopsOpportunitData.Opportunity_Probability + '%'
  //           : '–',
  //       },
  //       {
  //         icon: CurrencyDollarIcon,
  //         name: 'Expected revenue',
  //         value: dealopsOpportunitData.Opportunity_ExpectedRevenue,
  //       },
  //     ];
  //     break;
  //   case PricingFlowType.PENGUIN:
  //     const penguinOpportunityData: PenguinOpportunityData =
  //       opportunityData as PenguinOpportunityData;
  //     let penguinBadgeColor: BadgeColor = 'gray';
  //     switch (penguinOpportunityData.Opportunity_StageName) {
  //       case 'Closed Won':
  //         penguinBadgeColor = 'green';
  //         break;
  //       case 'Closed Lost':
  //         penguinBadgeColor = 'red';
  //         break;
  //     }

  //     fields = [
  //       {
  //         icon: UserIcon,
  //         name: 'Owner',
  //         value: penguinOpportunityData.User_Name ?? '–',
  //       },
  //       {
  //         icon: TagIcon,
  //         name: 'Industry',
  //         value: penguinOpportunityData.Account_Industry ?? '–',
  //       },
  //       {
  //         icon: GlobeAltIcon,
  //         name: 'Country',
  //         value: penguinOpportunityData.Account_BillingCountry ?? '–',
  //       },
  //       {
  //         icon: ClockIcon,
  //         name: 'Stage',
  //         value: penguinOpportunityData.Opportunity_StageName ? (
  //           <Badge color={penguinBadgeColor}>
  //             {penguinOpportunityData.Opportunity_StageName}
  //           </Badge>
  //         ) : (
  //           '–'
  //         ),
  //       },
  //       {
  //         icon: ArrowTrendingUpIcon,
  //         name: 'Likelihood to close',
  //         value: penguinOpportunityData.Opportunity_Probability
  //           ? penguinOpportunityData.Opportunity_Probability + '%'
  //           : '–',
  //       },
  //       {
  //         icon: CurrencyDollarIcon,
  //         name: 'Expected revenue',
  //         value: penguinOpportunityData.Opportunity_ExpectedRevenue,
  //       },
  //     ];
  //     break;
  //   case PricingFlowType.ALPACA:
  //     fields = [
  //       {
  //         icon: CurrencyDollarIcon,
  //         name: 'ALPACA field',
  //         value: 'ALPACA overview bar not implemented',
  //       },
  //     ];
  //     break;
  //   default:
  //     fields = [
  //       {
  //         icon: CurrencyDollarIcon,
  //         name: 'Unknown field',
  //         value: 'Unsupported pricing flow type for opportunity overview bar',
  //       },
  //     ];
  // }

  // return (
  //   <div
  //     className={classNames(
  //       props.stretchWidth ? 'w-full' : '',
  //       'rounded-lg border border-gray-200 inline-block py-6 px-8 bg-white',
  //     )}
  //   >
  //     <div
  //       className={classNames(
  //         props.stretchWidth ? 'justify-between w-full gap-6' : '',
  //         'flex flex-row gap-8 flex-wrap',
  //       )}
  //     >
  //       {fields.map(
  //         (field: { icon: any; name: string; value: any }, i: number) => {
  //           return (
  //             <div key={i} className="flex flex-row gap-3">
  //               <div className="border border-gray-200 shadow rounded-full h-10 w-10 flex justify-center">
  //                 <field.icon
  //                   className="text-gray-600 m-2"
  //                   aria-hidden="true"
  //                 />
  //               </div>
  //               <div className="flex flex-col gap-1">
  //                 <div className="text-gray-400 text-sm whitespace-nowrap">
  //                   {field.name}
  //                 </div>
  //                 <div
  //                   className={classNames(
  //                     loading ? 'opacity-0' : 'opacity-100',
  //                     'transition-opacity duration-500 ease-in-out text-gray-600 text-md whitespace-nowrap',
  //                   )}
  //                 >
  //                   {field.value}
  //                 </div>
  //                 {loading && <InlineSpinner />}
  //               </div>
  //             </div>
  //           );
  //         },
  //       )}
  //     </div>
  //   </div>
  // );
}
