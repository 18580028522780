import { isNil } from 'lodash';
import {
  PricingCurve,
  PricingFlowCommon,
  PricingFlowType,
  ProductCommon,
} from '../types';
import {
  Count,
  CurrencyValue,
  CurrencyValueFlat,
  CurrencyValueFlatAndPercent,
  CurrencyValuePercent,
  CurrencyValueTiered,
  CurrencyValueType,
  DerivedValue,
  Minimum,
  QuotePrice,
  ZERO_COUNT,
  ZERO_FLAT,
  ZERO_PERCENT,
} from '../types_common/price';
import { AlpacaStep } from './AlpacaPricingFlowPage';
import { getCurrencyConversionsNeeded, getForexRates } from './alpaca_utils';
import { RegionalData } from './IssuingData';
import { TreasuryFxTemplate } from './Steps/TreasuryFX';

// #AlpacaTypes
// These server types must be kept in sync with the client types!

// #AlpacaCategoriesAndSteps
export type AlpacaCategoryName =
  | 'Acquiring'
  | 'Global Accounts'
  | 'Treasury FX'
  | 'Collections'
  | 'Payouts'
  | 'Issuing';
type AlpacaSuperCategoryName =
  | 'Accept payments / Fund accounts'
  | 'Manage funds'
  | 'Make payouts / Send funds';
export type AlpacaDerivedAggregations = {
  estimatedVolume: DerivedValue<CurrencyValueFlat> | null;
  estimatedTransactionCount: DerivedValue<Count> | null;
  grossRevenue: DerivedValue<CurrencyValueFlat>;
  grossCost: DerivedValue<CurrencyValueFlat>;
  grossProfit: DerivedValue<CurrencyValueFlat>;
  profitMargin: DerivedValue<CurrencyValuePercent>;
  takeRate: DerivedValue<CurrencyValuePercent> | null;
};
// This lives on the PricingFlow and is hardcoded on the client (see
// ALL_ALPACA_PRODUCT_CATEGORIES below)
export type AlpacaProductCategory = {
  category: AlpacaCategoryName;
  superCategory: AlpacaSuperCategoryName;
  derivedAggregations?: AlpacaDerivedAggregations;
  disabled?: boolean;
};
// This is the additionalData field on the Category table
export type AlpacaCategoryAdditionalData = {};

// Note: ordering matters here! Supercategories are displayed left to right by
// order of appearance in this array, and categories are displayed top to bottom
// by appearance order
export const ALL_ALPACA_PRODUCT_CATEGORIES: AlpacaProductCategory[] = [
  {
    category: 'Acquiring',
    superCategory: 'Accept payments / Fund accounts',
    disabled: true,
  },
  {
    category: 'Global Accounts',
    superCategory: 'Accept payments / Fund accounts',
  },
  {
    category: 'Collections',
    superCategory: 'Accept payments / Fund accounts',
  },
  {
    category: 'Treasury FX',
    superCategory: 'Manage funds',
  },
  {
    category: 'Issuing',
    superCategory: 'Make payouts / Send funds',
  },
  {
    category: 'Payouts',
    superCategory: 'Make payouts / Send funds',
  },
];

export type AlpacaManualQuote = {
  // remove
  monthlyMinimum?: number;
  /** Prices for each product **/
  products?: { [key: string]: number };
};

export type AlpacaApprovalLevels = { [key: string]: number }; // remove

// Synced with server/src/utils/alpaca/alpaca_types.ts
export type AlpacaOpportunityData = {
  Opportunity__Owner_Name__c: string | null;
  Account__Customer_Segment__c: string | null;
  Account__Country__c: string | null;
  Opportunity__StageName: string | null;
  Opportunity__Probability: number | null;
  Opportunity__Amount: number | null;
  Opportunity__Total_Est_Monthly_Gross_Profit_Margin__c: number | null;
  OpportunityLineItems: AlpacaOpportunityLineItemRecord[] | null;
  Opportunity__Pricebook2Id: string | null;
};

export const ALPACA_DEFAULT_CURRENCY = 'USD';
export const ALPACA_SUPPORTED_CURRENCIES = [
  'USD',
  'EUR',
  'GBP',
  'AUD',
  'SGD',
  'HKD',
  // these are only used when consuming costs from the spreadsheets
  'JPY',
  'CAD',
  'CNY',
  'NZD',
  'THB',
  'KRW',
  'IDR',
  'INR',
  'MYR',
  'PHP',
] as const;

export const ALPACA_CURRENCY_SYMBOLS: {
  [key in AlpacaSupportedCurrency]: string;
} = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  AUD: 'A$',
  SGD: 'SGD ',
  HKD: 'HK$',
  // these are only used when consuming costs from the spreadsheets
  JPY: '¥',
  CAD: 'CA$',
  CNY: 'CN¥',
  NZD: 'NZ$',
  THB: 'THB ',
  KRW: '₩',
  IDR: 'IDR ',
  INR: '₹',
  MYR: 'MYR ',
  PHP: '₱',
};

export type AlpacaSupportedCurrency =
  (typeof ALPACA_SUPPORTED_CURRENCIES)[number];

export type ForexRates = {
  [key: string]: {
    [key: string]: number;
  };
};

export enum IssuingCountryCode {
  US = 'US',
  UK = 'UK',
  AU = 'AU',
  HK = 'HK',
  SG = 'SG',
  NL = 'NL',
}
const ALPACA_ISSUING_DEFAULT_ENTITY = IssuingCountryCode.US;

export const countryToCurrency: Record<
  IssuingCountryCode,
  AlpacaSupportedCurrency
> = {
  [IssuingCountryCode.US]: 'USD',
  [IssuingCountryCode.UK]: 'GBP',
  [IssuingCountryCode.AU]: 'AUD',
  [IssuingCountryCode.HK]: 'HKD',
  [IssuingCountryCode.SG]: 'SGD',
  [IssuingCountryCode.NL]: 'EUR',
};
export enum IssuingBinType {
  Commercial = 'Commercial',
  Consumer = 'Consumer',
  CommercialOTA = 'Commercial OTA',
}

export function DEFAULT_ISSUING_ENTITY_CONFIG(
  country: IssuingCountryCode,
  currency: AlpacaSupportedCurrency,
  existingOpportunityLineItems: AlpacaOpportunityLineItemRecord[] | null,
): IssuingEntity {
  const issuingOpportunityLineItem = existingOpportunityLineItems?.find(
    (lineItem) => lineItem.Product2Id === ISSUING_PRODUCT_2_ID,
  );

  let binType = IssuingBinType.Commercial;
  let physicalCardPercentage: CurrencyValuePercent = ZERO_PERCENT;

  if (issuingOpportunityLineItem) {
    // Try to default a bin type
    if (issuingOpportunityLineItem.Sub_Domain__c?.includes('Consumer Card')) {
      binType = IssuingBinType.Consumer;
    }

    // Try to default a physical card percentage
    // If Issuing_Card_Type__c is Virtual, then it's 0% physical
    // If Issuing_Card_Type__c is Physical, then it's 100% physical
    if (issuingOpportunityLineItem.Issuing_Card_Type__c === 'Physical') {
      physicalCardPercentage = {
        type: CurrencyValueType.PERCENT,
        value: 100,
      };
    }
  }

  return {
    name: country,
    issuingEntityCurrency: currency,
    binType,
    monthlySpendAtScale: ZERO_FLAT(currency),
    avgTransactionSize: ZERO_FLAT(currency),
    monthlyNumberOfCardsCreated: ZERO_COUNT,
    monthlyTransactionCount: ZERO_COUNT,
    addedToDigitalWalletPercentage: ZERO_PERCENT,
    physicalCardPercentage: physicalCardPercentage,
    walletLoadedBreakdown: {
      applePay: ZERO_PERCENT,
      googlePay: ZERO_PERCENT,
      neither: { type: CurrencyValueType.PERCENT, value: 100 },
    },
    regionalBreakdown:
      country === IssuingCountryCode.US
        ? {
            domestic: { type: CurrencyValueType.PERCENT, value: 50 },
            international: { type: CurrencyValueType.PERCENT, value: 50 },
            intraRegional: { type: CurrencyValueType.PERCENT, value: 0 },
          }
        : {
            domestic: { type: CurrencyValueType.PERCENT, value: 33.33 },
            international: { type: CurrencyValueType.PERCENT, value: 33.33 },
            intraRegional: { type: CurrencyValueType.PERCENT, value: 33.34 },
          },
    showRebates: false,
    showInterchangeRevenueShare: false,
    showTransactionFees: false,
  };
}

function DEFAULT_ISSUING_CONFIG(
  country: IssuingCountryCode,
  currency: AlpacaSupportedCurrency,
  existingOpportunityLineItems: AlpacaOpportunityLineItemRecord[] | null,
): AlpacaIssuingConfig {
  return {
    isCustomInterchangeRevenueEnabled: false,
    isShowLocalCurrencySelected: false,
    isMultiCountryIssuingEnabled: false,
    hasSelectedTemplate: false,
    currentlyViewingEntity: country,
    selectedEntities: [country],
    entities: [
      DEFAULT_ISSUING_ENTITY_CONFIG(
        country,
        currency,
        existingOpportunityLineItems,
      ),
    ],
  };
}

// #AlpacaLineItemType
// Synced with server/src/utils/alpaca/alpaca_types.ts
type AlpacaOpportunityLineItemRecord = {
  Monthly_margin__c: number | null;
  UnitPrice: number | null;
  Id: string;
  Product2Id: string;
  ProductCode: string;
  Sub_Domain__c: string | null;
  Issuing_Card_Type__c: string | null;
};
function getPreselectedProductCategories(
  opportunityLineItems: AlpacaOpportunityLineItemRecord[] | null,
): AlpacaProductCategory[] {
  if (!isNil(opportunityLineItems)) {
    const selectedCategoryNames: AlpacaCategoryName[] =
      opportunityLineItems.flatMap((lineItem) => {
        if (lineItem.ProductCode === 'Issuing') {
          return ['Issuing'];
        } else if (lineItem.ProductCode === 'GTPN') {
          return Array.from(
            (lineItem.Sub_Domain__c ?? '')
              .split(';')
              .reduce((acc, subDomain) => {
                if (subDomain === 'FX' || subDomain === 'LockFX') {
                  acc.add('Treasury FX');
                }
                if (subDomain === 'Mass Payout') {
                  acc.add('Payouts');
                }
                if (
                  subDomain === 'Mass Collection' &&
                  (process.env
                    .REACT_APP_ENABLE_COLLECTIONS_AND_GLOBAL_ACCOUNTS ===
                    'true' ??
                    false)
                ) {
                  acc.add('Collections');
                  acc.add('Global Accounts');
                }
                return acc;
              }, new Set<AlpacaCategoryName>()),
          );
        } else {
          return [];
        }
      });
    return ALL_ALPACA_PRODUCT_CATEGORIES.filter((c) =>
      selectedCategoryNames.includes(c.category),
    );
  }
  return [];
}

// Synced with server/src/utils/alpaca/opportunity_line_items.ts
const ALPACA_PRICEBOOK2ID = '01s6F000009b1qSQAQ';
const GTPN_PRODUCT_2_ID = '01tBB000000G396YAC';
const GTPN_PRICEBOOK_ENTRY_ID = '01uBB000000aXwBYAU';
const ISSUING_PRODUCT_2_ID = '01t6F00000C7TmdQAF';
const ISSUING_PRICEBOOK_ENTRY_ID = '01u6F00000ljYBlQAM';
const MONTHLY_FEE_PRODUCT_2_ID = '01t6F00000CAeKDQA1';
const MONTHLY_FEE_PRICEBOOK_ENTRY_ID = '01u6F00000nwmeqQAA';
type AlpacaProductType = 'GTPN' | 'Issuing' | 'MonthlyFee';
const IDS = {
  GTPN: {
    Product2Id: GTPN_PRODUCT_2_ID,
    PricebookEntryId: GTPN_PRICEBOOK_ENTRY_ID,
  },
  Issuing: {
    Product2Id: ISSUING_PRODUCT_2_ID,
    PricebookEntryId: ISSUING_PRICEBOOK_ENTRY_ID,
  },
  MonthlyFee: {
    Product2Id: MONTHLY_FEE_PRODUCT_2_ID,
    PricebookEntryId: MONTHLY_FEE_PRICEBOOK_ENTRY_ID,
  },
};
const GTPN_CATEGORIES: Set<AlpacaCategoryName> = new Set([
  'Global Accounts',
  'Payouts',
  'Treasury FX',
  'Collections',
]);
// ENDSYNC

function getPreselectedMonthlyFee(
  existingOpportunityLineItems: AlpacaOpportunityLineItemRecord[] | null,
  quoteCurrency: AlpacaSupportedCurrency,
  forexRates: ForexRates,
): CurrencyValueFlat | null {
  const monthlySubscriptionFee = existingOpportunityLineItems?.find(
    (lineItem) =>
      lineItem.Product2Id === MONTHLY_FEE_PRODUCT_2_ID &&
      lineItem.Sub_Domain__c?.includes('Monthly Fee'),
  );
  if (monthlySubscriptionFee) {
    const monthlyFee =
      Math.max(
        monthlySubscriptionFee.UnitPrice ?? 0,
        monthlySubscriptionFee.Monthly_margin__c ?? 0,
      ) ?? 0;
    // Convert to quoteCurrency
    if (quoteCurrency !== 'USD') {
      const forexRate = 1.0 / forexRates[quoteCurrency]['USD'];
      return {
        type: CurrencyValueType.FLAT,
        value: monthlyFee * forexRate,
        currency: quoteCurrency,
      };
    }
  }
  return null;
}

export async function getDefaultAdditionalData(
  pricingFlow: AlpacaPricingFlow,
): Promise<AlpacaAdditionalData> {
  // Try to set the quote currency to the opportunity's account's country, but default to ALPACA_DEFAULT_CURRENCY
  // And the default Issuing Entity to the opportunity's account's country, but default to ALPACA_ISSUING_DEFAULT_ENTITY
  const opportunityCountry = (pricingFlow.opportunityData.Account__Country__c ??
    ALPACA_ISSUING_DEFAULT_ENTITY) as IssuingCountryCode;
  const issuingEntityCountry = Object.values(IssuingCountryCode).includes(
    opportunityCountry,
  )
    ? opportunityCountry
    : ALPACA_ISSUING_DEFAULT_ENTITY;
  const quoteCurrency =
    countryToCurrency[issuingEntityCountry] ?? ALPACA_DEFAULT_CURRENCY;

  const currencyConversions = getCurrencyConversionsNeeded(
    pricingFlow,
    quoteCurrency,
  );
  const forexRates = await getForexRates(currencyConversions);

  return {
    quoteCurrency: quoteCurrency,
    productCategories: getPreselectedProductCategories(
      pricingFlow.opportunityData.OpportunityLineItems,
    ),
    treasuryFXPricingTemplate: null,
    customStage: null,
    treasuryStep: null,
    monthlySubscriptionFee: getPreselectedMonthlyFee(
      pricingFlow.opportunityData.OpportunityLineItems,
      quoteCurrency,
      forexRates,
    ),
    forexRates,
    issuingConfig: DEFAULT_ISSUING_CONFIG(
      issuingEntityCountry,
      quoteCurrency,
      pricingFlow.opportunityData.OpportunityLineItems,
    ),
    shouldCurrencyValuesBeConsistent: true,
  };
}
export type MonthlySubscriptionFee = QuotePrice<
  CurrencyValueFlat | CurrencyValueTiered<CurrencyValueFlat, Minimum>
>;
export type IssuingEntity = {
  name: IssuingCountryCode;
  issuingEntityCurrency: AlpacaSupportedCurrency;
  binType: IssuingBinType;
  monthlySpendAtScale: CurrencyValueFlat;
  avgTransactionSize: CurrencyValueFlat;
  monthlyNumberOfCardsCreated: Count;
  monthlyTransactionCount: Count;
  addedToDigitalWalletPercentage: CurrencyValuePercent;
  physicalCardPercentage: CurrencyValuePercent;
  walletLoadedBreakdown: {
    applePay: CurrencyValuePercent;
    googlePay: CurrencyValuePercent;
    neither: CurrencyValuePercent;
  };
  regionalBreakdown: RegionalData<CurrencyValuePercent>;
  showRebates: boolean;
  showInterchangeRevenueShare: boolean;
  showTransactionFees: boolean;
};
export type AlpacaIssuingConfig = {
  isCustomInterchangeRevenueEnabled: boolean;
  isShowLocalCurrencySelected: boolean;
  isMultiCountryIssuingEnabled: boolean;
  hasSelectedTemplate: boolean;
  entities: IssuingEntity[];
  currentlyViewingEntity: IssuingCountryCode;
  // An issuing entity might have data present in the entities array but is not
  // currently selected if a rep did some configuring for an entity but
  // ultimately decided not to present it on the quote. This way, we don't lose
  // their work in progress if they decide to later bring the entity back.
  selectedEntities: IssuingCountryCode[];
};

export type AlpacaAdditionalData = {
  quoteCurrency: AlpacaSupportedCurrency;
  productCategories: AlpacaProductCategory[] | null;
  treasuryFXPricingTemplate: TreasuryFxTemplate | null;
  customStage: AlpacaStep | null;
  treasuryStep: 1 | 2 | null;
  monthlySubscriptionFee: MonthlySubscriptionFee | null;
  forexRates: ForexRates;
  derivedAggregations?: AlpacaDerivedAggregations;
  issuingConfig: AlpacaIssuingConfig;
  // This should be `true` whenever we've finished awaiting getForexRates (e.g.
  // forexRates should be up to date), and `false` right after we've made some
  // change that affects which quote currencies we need, e.g. changing the quote
  // currency or adding a new issuing entity when show local currencies is
  // enabled. This determines whether we get paged for errors with missing
  // currencies in the forexRates object. The overall flow is:
  // - Change is made (e.g. quote currency is changed)
  // - Pricing flow is updated, and various converter functions all over the
  //   place might complaining about not having the new currency available in
  //   forex rates. We do NOT care about these
  // - #AlpacaForexEffects run, which refreshes the forexRates object and makes
  //   currency values consistent
  // - If there are any errors with missing currencies after that, we DO care
  //   about those errors
  shouldCurrencyValuesBeConsistent: boolean;
};

// Alpaca Products
interface AlpacaProductCommon extends ProductCommon {
  quotePrice: QuotePrice | null;
  categoryName: AlpacaCategoryName;
  customRampUp?: number[];
  linearRampUpConfig?: { start: number; months: number };
  transactionCount?: number; // Required if the quotePrice.type is 'flat_and_percent' or 'percent' or tiered with these types
  derivedAggregations?: AlpacaDerivedAggregations;
}

export interface AlpacaPayoutProduct extends AlpacaProductCommon {
  categoryName: 'Payouts';
}

export interface AlpacaCollectionsProduct extends AlpacaProductCommon {
  categoryName: 'Collections';
}

export interface AlpacaGlobalAccountsProduct extends AlpacaProductCommon {
  categoryName: 'Global Accounts';
}

export type AlpacaProduct =
  | AlpacaPayoutProduct
  | AlpacaTreasuryFxProduct
  | AlpacaIssuingProduct
  | AlpacaCollectionsProduct
  | AlpacaGlobalAccountsProduct;

export type AlpacaIssuingProductPrice =
  | AlpacaIssuingChargebackProductPrice
  | AlpacaIssuingInterchangeRevenueProductPrice
  | AlpacaIssuingCardCreationCostProductPrice
  | AlpacaIssuingTransactionCostProductPrice
  | AlpacaIssuingPerfIncentivesProductPrice
  | AlpacaIssuingCardMaintenanceProductPrice;
interface AlpacaIssuingChargebackProductPrice
  extends AlpacaIssuingProductPriceCommon {
  skuSubgroup: 'chargeback';
  geography: null;
  binType: null;
  digitalWallet: null;
}
interface AlpacaIssuingInterchangeRevenueProductPrice
  extends AlpacaIssuingProductPriceCommon {
  skuSubgroup: 'interchangeRevenue';
  digitalWallet: null;
}
interface AlpacaIssuingCardCreationCostProductPrice
  extends AlpacaIssuingProductPriceCommon {
  skuSubgroup: 'cardCreationCost';
  binType: null;
  geography: null;
  digitalWallet: null;
}
interface AlpacaIssuingTransactionCostProductPrice
  extends AlpacaIssuingProductPriceCommon {
  skuSubgroup: 'transactionCost';
  binType: null;
  digitalWallet: null;
}
interface AlpacaIssuingPerfIncentivesProductPrice
  extends AlpacaIssuingProductPriceCommon {
  skuSubgroup: 'perfIncentives';
  binType: IssuingBinType.CommercialOTA;
  geography: null;
  digitalWallet: null;
}
interface AlpacaIssuingCardMaintenanceProductPrice
  extends AlpacaIssuingProductPriceCommon {
  skuSubgroup: 'cardMaintenanceCost';
  binType: null;
  geography: null;
  digitalWallet: 'apple';
}
interface AlpacaIssuingSelector {
  country: IssuingCountryCode;
  geography: IssuingGeography | null;
  binType: IssuingBinType | null;
  digitalWallet: IssuingDigitalWallet | null;
}
export function isUnpricedSubcategory(
  subCategory: IssuingSubcategory,
): subCategory is UnpricedIssuingSubcategories {
  return (UNPRICED_ISSUING_SUBCATEGORIES as ReadonlyArray<string>).includes(
    subCategory,
  );
}

export const ISSUING_SUBCATEGORIES_WITH_GEOGRAPHY = [
  'transactionFees',
  'rebates',
  'interchangeRevenueShare',
  'interchangeRevenue',
  'transactionCost',
  'perfIncentives',
] as const;
export type AlpacaIssuingRowSelector =
  | {
      subCategory: (typeof ISSUING_SUBCATEGORIES_WITH_GEOGRAPHY)[number];
      geography: IssuingGeography;
    }
  | {
      subCategory: 'chargeback' | 'cardMaintenanceCost' | 'cardCreationCost';
      geography: null;
    };

interface AlpacaIssuingProductPriceCommon
  extends AlpacaProductPriceCommon,
    AlpacaIssuingSelector {
  skuGroup: 'Issuing';
  skuSubgroup: unknown;
  digitalWallet: IssuingDigitalWallet | null;
}

export const issuingCountryCodeToCountry = {
  [IssuingCountryCode.US]: 'United States',
  [IssuingCountryCode.UK]: 'United Kingdom',
  [IssuingCountryCode.AU]: 'Australia',
  [IssuingCountryCode.HK]: 'Hong Kong',
  [IssuingCountryCode.SG]: 'Singapore',
  [IssuingCountryCode.NL]: 'Netherlands',
};

export const issuingCountryCodeToEmoji = {
  [IssuingCountryCode.US]: '🇺🇸',
  [IssuingCountryCode.UK]: '🇬🇧',
  [IssuingCountryCode.AU]: '🇦🇺',
  [IssuingCountryCode.HK]: '🇭🇰',
  [IssuingCountryCode.SG]: '🇸🇬',
  [IssuingCountryCode.NL]: '🇳🇱',
};
export const ISSUING_GEOGRAPHIES = [
  'domestic',
  'international',
  'intraRegional',
] as const;
export type IssuingGeography = (typeof ISSUING_GEOGRAPHIES)[number];
export const ISSUING_DIGITAL_WALLETS = ['excl', 'apple', 'google'] as const;
export type IssuingDigitalWallet = (typeof ISSUING_DIGITAL_WALLETS)[number];

export const ISSUING_SUBCATEGORIES = [
  'cardCreationCost',
  'cardMaintenanceCost',
  'chargeback',
  'interchangeRevenue',
  'interchangeRevenueShare',
  'perfIncentives',
  'rebates',
  'transactionCost',
  'transactionFees',
] as const;

// Typecheck to verify that ISSUING_SUBCATEGORIES and IssuingSubcategory stay in sync
type _IssuingSubcategoryArray = (typeof ISSUING_SUBCATEGORIES)[number];
const _ensureAllSubcategories: IssuingSubcategory extends _IssuingSubcategoryArray
  ? true
  : false = true;

const UNPRICED_ISSUING_SUBCATEGORIES = [
  'transactionFees',
  'rebates',
  'interchangeRevenueShare',
] as const;
type UnpricedIssuingSubcategories =
  (typeof UNPRICED_ISSUING_SUBCATEGORIES)[number];

export type IssuingSubcategory =
  | UnpricedIssuingSubcategories
  | AlpacaIssuingProductPrice['skuSubgroup'];

interface AlpacaIssuingProductCommon
  extends AlpacaProductCommon,
    AlpacaIssuingSelector {
  categoryName: 'Issuing';
  subCategory: IssuingSubcategory;
  isVolumeEditable: boolean;
  // some products represent revenue, others represent costs.
  // For revenue products, quotePrice should be a CurrencyValue, and quoteCost
  // should be null.
  // For cost products, quotePrice should be null, and quoteCost should be a
  // CurrencyValue
  isCost: boolean;
  quoteCost?: QuotePrice | null;
  // some products, e.g. transaction costs, have pricing info in the pricebook
  // (spreadsheet). Others, e.g. rebates, do not.
  hasPricingInfo: boolean;
  pricingInformation?: AlpacaIssuingPricingInformation | null;
}
interface AlpacaIssuingUnpricedRevenueProduct
  extends AlpacaIssuingProductCommon {
  subCategory: 'transactionFees';
  isCost: false;
  hasPricingInfo: false;
}
interface AlpacaIssuingPricedRevenueProduct extends AlpacaIssuingProductCommon {
  subCategory: 'interchangeRevenue' | 'perfIncentives';
  isCost: false;
  hasPricingInfo: true;
  pricingInformation: AlpacaIssuingPricingInformation;
}
interface AlpacaIssuingPricedCostProduct extends AlpacaIssuingProductCommon {
  subCategory: 'cardCreationCost' | 'cardMaintenanceCost' | 'chargeback';
  isCost: true;
  quoteCost: QuotePrice;
  hasPricingInfo: true;
  pricingInformation: AlpacaIssuingPricingInformation;
}
interface AlpacaIssuingTransactionCostProduct
  extends AlpacaIssuingProductCommon {
  subCategory: 'transactionCost';
  isCost: true;
  quoteCost: QuotePrice;
  hasPricingInfo: true;
  pricingInformation: AlpacaIssuingPricingInformation;
  digitalWallet: IssuingDigitalWallet;
}

interface AlpacaIssuingUnpricedCostProduct extends AlpacaIssuingProductCommon {
  subCategory: 'rebates' | 'interchangeRevenueShare';
  isCost: true;
  quoteCost: QuotePrice;
  hasPricingInfo: false;
}
export type AlpacaIssuingProduct =
  | AlpacaIssuingUnpricedCostProduct
  | AlpacaIssuingPricedCostProduct
  | AlpacaIssuingUnpricedRevenueProduct
  | AlpacaIssuingPricedRevenueProduct
  | AlpacaIssuingTransactionCostProduct;

export const ALPACA_FX_BUCKETS = [
  'Major Currencies',
  'Exotic Tier 1',
  'Exotic Tier 2',
  'Exotic Tier 3',
  // 'Exotic Tier 4',
] as const;
export type AlpacaTFxBucket = (typeof ALPACA_FX_BUCKETS)[number];

interface AlpacaTreasuryFxProductCommon extends AlpacaProductCommon {
  categoryName: 'Treasury FX';
  subCategory: unknown;
}
export type AlpacaTFxSingleBuyCurrencyProduct =
  AlpacaTreasuryFxProductCommon & {
    currencyId: string; // alpaca product id
    subCategory: 'single_buy';
  };
export interface AlpacaTFxBucketProduct extends AlpacaTreasuryFxProductCommon {
  currencyIds: string[]; // alpaca product id
  bucket: AlpacaTFxBucket;
  subCategory: 'bucket';
}
export interface AlpacaTFxPairProduct extends AlpacaTreasuryFxProductCommon {
  sellCurrencyId: string; // alpaca product id
  buyCurrencyId: string; // alpaca product id
  subCategory: 'pair';
}
export function isAlpacaTFXProduct(
  product: AlpacaProduct,
): product is AlpacaTreasuryFxProduct {
  return product.categoryName === 'Treasury FX';
}
export type AlpacaTreasuryFxProduct = { categoryName: 'Treasury FX' } & (
  | AlpacaTFxBucketProduct
  | AlpacaTFxSingleBuyCurrencyProduct
  | AlpacaTFxPairProduct
);

export type AlpacaCurrentPricingCurves = {
  [productId: string]: AlpacaPricingCurve;
};
export interface AlpacaPricingFlow extends PricingFlowCommon {
  // Quote data
  type: PricingFlowType.ALPACA;
  opportunityData: AlpacaOpportunityData;
  additionalData: AlpacaAdditionalData;
  products: AlpacaProduct[] | null;
  pricingSheetData: AlpacaPricingSheet;
  currentPricingCurves: AlpacaCurrentPricingCurves;
}

interface AlpacaPricingInformationCommon {
  skuGroup: AlpacaCategoryName;
  listPrice: CurrencyValue | null;
  cost: CurrencyValue | CurrencyValueTiered | null;
  validQuotePriceTypes: CurrencyValueType[];
}
export interface AlpacaTFxPricingInformation
  extends AlpacaPricingInformationCommon {
  skuGroup: 'Treasury FX';
  listPrice: CurrencyValuePercent;
  cost: CurrencyValuePercent;
}

export type AlpacaIssuingPricingInformation =
  | AlpacaIssuingChargebackPricingInformation
  | AlpacaIssuingInterchangeRevenuePricingInformation
  | AlpacaIssuingCardCreationCostPricingInformation
  | AlpacaIssuingTransactionCostPricingInformation
  | AlpacaIssuingCardMaintenancePricingInformation
  | AlpacaIssuingPerfIncentivesPricingInformation;
interface AlpacaIssuingChargebackPricingInformation
  extends AlpacaIssuingPricingInformationCommon {
  skuSubgroup: 'chargeback';
  listPrice: null;
  cost: CurrencyValue;
}
interface AlpacaIssuingInterchangeRevenuePricingInformation
  extends AlpacaIssuingPricingInformationCommon {
  skuSubgroup: 'interchangeRevenue';
  cost: null;
  listPrice: CurrencyValue;
}
interface AlpacaIssuingCardCreationCostPricingInformation
  extends AlpacaIssuingPricingInformationCommon {
  skuSubgroup: 'cardCreationCost';
  listPrice: null;
  cost: CurrencyValue;
}
interface AlpacaIssuingCardMaintenancePricingInformation
  extends AlpacaIssuingPricingInformationCommon {
  skuSubgroup: 'cardMaintenanceCost';
  listPrice: null;
  cost: CurrencyValue;
}
interface AlpacaIssuingPerfIncentivesPricingInformation
  extends AlpacaIssuingPricingInformationCommon {
  skuSubgroup: 'perfIncentives';
  listPrice: CurrencyValue;
  cost: null;
}
interface AlpacaIssuingTransactionCostPricingInformation
  extends AlpacaIssuingPricingInformationCommon {
  skuSubgroup: 'transactionCost';
  cost: CurrencyValueTiered<CurrencyValueFlatAndPercent, CurrencyValueFlat>;
  listPrice: null;
  digitalWallet: IssuingDigitalWallet;
}
interface AlpacaIssuingPricingInformationCommon
  extends AlpacaPricingInformationCommon {
  skuGroup: 'Issuing';
}

type AlpacaPricingInformation =
  | AlpacaTFxPricingInformation
  | AlpacaIssuingPricingInformation
  | AlpacaCollectionsPricingInformation;

interface AlpacaCollectionsPricingInformation
  extends AlpacaPricingInformationCommon {
  skuGroup: 'Collections';
  listPrice: CurrencyValueFlat | null;
  cost: CurrencyValueFlat;
}

export type AlpacaPricingCurve = PricingCurve & {
  pricingInformation: AlpacaPricingInformation;
};

type AlpacaProductPriceCommon = {
  id: string;
  name: string;
  skuGroup: AlpacaCategoryName;
  pricingCurves: AlpacaPricingCurve[];
};
export function isAlpacaTFXProductPrice(
  product: AlpacaProductPriceCommon,
): product is AlpacaTFxProductPrice {
  return product.skuGroup === 'Treasury FX';
}
export interface AlpacaTFxProductPrice extends AlpacaProductPriceCommon {
  skuSubgroup: AlpacaTFxBucket;
  skuGroup: 'Treasury FX';
}
export interface AlpacaCollectionsProductPrice
  extends AlpacaProductPriceCommon {
  skuGroup: 'Collections';
  skuSubgroup: AlpacaCollectionsSkuSubgroup;
  country: string;
}

export enum AlpacaCollectionsSkuSubgroup {
  SWIFTCollection = 'SWIFT Collection',
  Local = 'Local',
  DirectDebit = 'DD',
  SWIFT = 'SWIFT',
}

export interface AlpacaGlobalAccountsProductPrice
  extends AlpacaProductPriceCommon {
  skuGroup: 'Global Accounts';
  country: string;
}

export interface AlpacaPayoutProductPrice extends AlpacaProductPriceCommon {
  skuGroup: 'Payouts';
  skuSubgroup: string;
  isSuggested?: boolean | null;
}

export type AlpacaProductPrice =
  | AlpacaTFxProductPrice
  | AlpacaCollectionsProductPrice
  | AlpacaIssuingProductPrice
  | AlpacaPayoutProductPrice
  | AlpacaGlobalAccountsProductPrice;

export type AlpacaPricingSheet = {
  countryPricingSheets: {
    [country: string]: {
      productInfo: AlpacaProductPrices;
    };
  };
};
export type AlpacaProductPrices = {
  [productId: string]: AlpacaProductPrice;
};
