import { CubeTransparentIcon, TrashIcon } from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import { FormattedNumberField } from 'src/components/Fields';
import SelectInput from 'src/components/SelectInput';
import { useToast } from 'src/components/Toast';
import { classNames } from 'src/dashboard/App';
import { getHandleKeyDownForEnterNextRowHandling } from 'src/utils';
import { User } from '../../../../types';
import { formatCurrencyValue } from '../../Alpaca/alpaca_utils';
import { usePricingFlowContext } from '../../PricingFlow';
import { PricingFlowStage, RampUpTypeIdentifier } from '../../types';
import { Count } from '../../types_common/price';
import BottomBar from '../Components/BottomBar';
import EditableIndicator from '../Components/EditableIndicator';
import OpportunityOverviewBar from '../Components/OpportunityOverviewBar';
import { calculateLinearRampUp } from '../Components/PenguinQuoteTable';
import StepDetailsTopbar from '../Components/StepDetailsTopbar';
import { PenguinFlowSteps } from '../PenguinPricingFlowPage';
import {
  PenguinCustomStageType,
  PenguinPricingFlow,
  PenguinProduct,
  PenguinProductPrices,
  PenguinProductWithVolume,
} from '../penguin_types';
import {
  unselectProduct,
  updatePricingFlowWithProducts,
} from './Step2ProductSelection';
import { useVolumeSuggestionsContext } from './VolumeSuggestionsContext';

const PROFESSIONAL_SERVICES_PRODUCTS = 'Platform and Professional Services';

const ensureProductHasVolume = (
  product: PenguinProduct,
  volumeSuggestion?: number,
  volumeSuggestionProducts?: string[],
): PenguinProductWithVolume => {
  let volume: Count;
  if (product.volume == null) {
    if (
      volumeSuggestion !== undefined &&
      volumeSuggestionProducts?.includes(product.name)
    ) {
      volume = { type: 'count', value: volumeSuggestion };
    } else {
      volume = { type: 'count', value: 0 };
    }
  } else {
    volume = product.volume ?? { type: 'count', value: 0 };
  }

  return { ...product, volume };
};

export default function Step3VolumeSelection(props: {
  validateStep: (pricingFlow: PenguinPricingFlow) => {
    value: boolean;
    error: string | null;
  };
  user: User;
  previousStage: () => void;
}) {
  const { previousStage, validateStep } = props;
  const { pricingFlow, updateFlow, editMode, setStage } =
    usePricingFlowContext<PenguinPricingFlow>();
  const products = (pricingFlow?.products as PenguinProduct[]) ?? [];

  const setProducts = (products: PenguinProduct[]) => {
    updatePricingFlowWithProducts({ pricingFlow, updateFlow, products });
  };

  // Deleting products should update flow immediately
  const deleteProduct = (product: PenguinProduct) => {
    updatePricingFlowWithProducts({
      pricingFlow,
      updateFlow,
      products: unselectProduct({
        id: product.id,
        products,
        productPrices,
      }),
    });
  };

  const pricingSheetData = pricingFlow.pricingSheetData;
  const productPrices = pricingSheetData.productInfo;
  const categories = pricingSheetData.categories;
  const { showToast } = useToast();
  const createAnalyticsEvent = useAnalyticsContext();

  let { volumeSuggestion, volumeSuggestionProducts } =
    useVolumeSuggestionsContext();

  const submit = async () => {
    // turn PenguinProduct[] into PenguinProductWithVolume[]
    let productsWithVolume = products.map((product) =>
      ensureProductHasVolume(
        product,
        volumeSuggestion,
        volumeSuggestionProducts,
      ),
    );
    const productsThatUsedSuggestions = products
      .map((p) => {
        if (volumeSuggestionProducts?.includes(p.name) && p.volume == null) {
          return p;
        }
        return null;
      })
      .filter((p): p is PenguinProduct => !isNil(p));
    if (productsThatUsedSuggestions.length > 0) {
      console.log(
        'Used volume suggestions for products:',
        productsThatUsedSuggestions,
      );
      createAnalyticsEvent({
        name: 'pricing_flow__used_volume_suggestion',
        eventData: {
          pricing_flow_id: pricingFlow.id,
          products: productsThatUsedSuggestions.map((p) => p.id),
        },
      });
    }

    productsWithVolume.forEach((product) =>
      updateProduct({
        product,
        volume: product.volume,
        subscriptionTerms: pricingFlow.additionalData.subscriptionTerms,
      }),
    );

    const { value, error } = validateStep({
      ...pricingFlow,
      products: productsWithVolume,
    });
    if (!value) {
      if (error) {
        showToast({
          title: error,
          subtitle: '',
          type: 'error',
        });
      }
      return;
    }

    if (!editMode) {
      setStage({
        stage: PricingFlowStage.CALCULATE_PRICE,
        customStage:
          PenguinFlowSteps[
            PenguinFlowSteps.indexOf(PenguinCustomStageType.PRICING_ADJUSTMENTS)
          ],
      });
      return;
    }

    updateFlow({
      ...pricingFlow,
      products: productsWithVolume,
      stage: PricingFlowStage.CALCULATE_PRICE,
      additionalData: {
        ...pricingFlow.additionalData,
        customStage:
          PenguinFlowSteps[
            PenguinFlowSteps.indexOf(PenguinCustomStageType.PRICING_ADJUSTMENTS)
          ],
      },
    });
  };

  let ungroupedProducts: PenguinProduct[] = products;
  let professionalServicesProducts: PenguinProduct[] = [];

  let sections = Object.keys(categories).map((category) => {
    const subcategories = categories[category].subcategories;

    let filteredProducts: PenguinProduct[] = [];

    Object.keys(subcategories).forEach((subcategoryName) => {
      if (subcategoryName === PROFESSIONAL_SERVICES_PRODUCTS) {
        professionalServicesProducts = professionalServicesProducts.concat(
          ungroupedProducts.filter((product) => {
            // TODO ROMAN
            return (
              subcategories[PROFESSIONAL_SERVICES_PRODUCTS].products.includes(
                product.name,
              ) &&
              professionalServicesProducts.filter(
                (p) => p.name === product.name,
              ).length === 0
            );
          }),
        );
      } else {
        filteredProducts = filteredProducts.concat(
          ungroupedProducts.filter((product) => {
            return subcategories[subcategoryName].products.includes(
              product.name,
            );
          }),
        );
      }
    });

    ungroupedProducts = ungroupedProducts.filter((product) => {
      return (
        !filteredProducts.includes(product) &&
        !professionalServicesProducts.includes(product)
      );
    });

    if (filteredProducts.length === 0) {
      return null;
    }
    return (
      <ProductsTable
        key={category}
        sectionName={category}
        productsToShow={filteredProducts}
        allProducts={products}
        productPrices={productPrices}
        setProducts={setProducts}
        deleteProduct={deleteProduct}
        subscriptionTerms={pricingFlow.additionalData.subscriptionTerms}
      />
    );
  });

  if (ungroupedProducts.length > 0) {
    sections.push(
      <ProductsTable
        key={'ungrouped'}
        productsToShow={ungroupedProducts}
        allProducts={products}
        productPrices={productPrices}
        setProducts={setProducts}
        deleteProduct={deleteProduct}
        subscriptionTerms={pricingFlow.additionalData.subscriptionTerms}
      />,
    );
  }

  if (professionalServicesProducts.length > 0) {
    sections.push(
      <ProductsTable
        key={PROFESSIONAL_SERVICES_PRODUCTS}
        sectionName={PROFESSIONAL_SERVICES_PRODUCTS}
        productsToShow={professionalServicesProducts}
        allProducts={products}
        productPrices={productPrices}
        setProducts={setProducts}
        deleteProduct={deleteProduct}
        subscriptionTerms={pricingFlow.additionalData.subscriptionTerms}
      />,
    );
  }

  return (
    <div className="flex w-full grow flex-row overflow-hidden">
      <OpportunityOverviewBar opportunityData={pricingFlow.opportunityData} />
      <div className="flex w-full grow flex-col overflow-auto px-6 pb-32">
        <StepDetailsTopbar
          stepName="Volume Inputs"
          stepDescription="Select the desired volume options for all the products"
          stepIcon={
            <CubeTransparentIcon className="h-6 w-6" aria-hidden="true" />
          }
        />
        <div className="mt-4 rounded-xl border border-gray-200 bg-gray-100 px-6 py-4">
          <b>Note:</b> Volume usage data is used to help Dealops calculate deal
          pricing, and is not written to Salesforce. You can configure monthly
          minimums and ramp ups in the next stage.
        </div>
        {sections}
        <hr className="col-span-4 my-4" />
        <div className="flex w-full flex-col items-center text-sm">
          <button className="underline" onClick={previousStage}>
            Add more products
          </button>
        </div>
      </div>

      <BottomBar
        primaryButtonProps={{ label: 'Next', onClick: submit }}
        secondaryButtonProps={{
          label: 'Back',
          onClick: async () => previousStage(),
        }}
      />
    </div>
  );
}

const ProductsTable = (props: {
  sectionName?: string;
  productsToShow: PenguinProduct[];
  allProducts: PenguinProduct[];
  productPrices: PenguinProductPrices;
  setProducts: (products: PenguinProduct[]) => void;
  deleteProduct: (product: PenguinProduct) => void;
  subscriptionTerms: number;
}) => {
  const {
    productsToShow,
    allProducts,
    productPrices,
    setProducts,
    sectionName,
    deleteProduct,
    subscriptionTerms,
  } = props;
  if (!productsToShow || productsToShow.length === 0) {
    return (
      <p className="mt-4 text-sm text-gray-600">
        Start by adding products and volume for this deal.
      </p>
    );
  }

  return (
    <div className="mx-auto mt-4 w-full">
      <div className="w-full rounded-xl border border-gray-200 bg-white">
        <table className="h-full w-full border-separate border-spacing-0">
          <thead>
            <tr>
              <th
                scope="col"
                className="sticky top-0 z-10 w-1/2 min-w-[172px] max-w-[240px] rounded-tl-xl border-r border-gray-200 bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
              >
                {sectionName ?? 'Other products'}
              </th>
              <th
                scope="col"
                className="has-tooltip sticky top-0 z-10 w-4/12 border-r border-gray-200 bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
              >
                Estimated usage volume
                <span className="tooltip z-20 -ml-60 -mt-12 whitespace-nowrap rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700">
                  How do you expect that the customer will ramp product usage?
                </span>
              </th>
              <th
                scope="col"
                className="sticky top-0 z-0 w-2/12 border-gray-200 bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
              >
                Monthly volume at scale
              </th>
              <th
                scope="col"
                className="sticky top-0 z-10 w-6 rounded-tr-xl border-gray-200 bg-gray-50 py-3.5 backdrop-blur backdrop-filter"
              >
                <span className="sr-only">Delete</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {[productsToShow].length > 0
              ? productsToShow.map((product) => (
                  <ProductRowInput
                    key={product.id}
                    product={product}
                    productPrices={productPrices}
                    setProducts={setProducts}
                    products={allProducts}
                    deleteProduct={deleteProduct}
                    subscriptionTerms={subscriptionTerms}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const DEFAULT_VOLUME_RAMP_UP_MONTHS = 12;

export function getVolumeRampUpMonthsForLinear(subscriptionTerms: number) {
  return Math.min(subscriptionTerms, DEFAULT_VOLUME_RAMP_UP_MONTHS);
}

function updateProduct({
  product,
  subscriptionTerms,
  rampUpTypeIdentifier,
  volume,
  volumeSuggestionProducts,
  setVolumeSuggestion,
  volumeSuggestion,
}: {
  product: PenguinProduct;
  subscriptionTerms: number;
  rampUpTypeIdentifier?: RampUpTypeIdentifier;
  volume?: Count;
  volumeSuggestionProducts?: string[];
  setVolumeSuggestion?: (suggestion: number) => void;
  volumeSuggestion?: number;
}): PenguinProductWithVolume {
  if (volume) {
    if (volumeSuggestionProducts?.includes(product.name)) {
      if (setVolumeSuggestion !== undefined) {
        setVolumeSuggestion(volume.value);
      }
    }
  }

  const volumeSuggestionTyped: Count | undefined = volumeSuggestion
    ? { type: 'count', value: volumeSuggestion }
    : undefined;
  let updatedProduct: PenguinProductWithVolume = {
    ...product,
    volume: volume ??
      volumeSuggestionTyped ??
      product.volume ?? { type: 'count', value: 0 },
  };

  rampUpTypeIdentifier = rampUpTypeIdentifier ?? updatedProduct.rampType;

  if (
    rampUpTypeIdentifier === 'linear_quarterly' ||
    rampUpTypeIdentifier === 'linear'
  ) {
    updatedProduct = {
      ...updatedProduct,
      rampType: rampUpTypeIdentifier,
      linearRampUpConfig: {
        start: 0,
        months: getVolumeRampUpMonthsForLinear(subscriptionTerms),
      },
    };
  } else if (rampUpTypeIdentifier === 'custom') {
    // over subscriptionTerms # of months linear ramp, do it in a functional way
    updatedProduct = {
      ...updatedProduct,
      rampType: rampUpTypeIdentifier,
      customRampUp: calculateLinearRampUp({
        steps: subscriptionTerms,
        volume: updatedProduct.volume.value,
        start: 0,
      }),
    };
  } else {
    updatedProduct = {
      ...updatedProduct,
      rampType: rampUpTypeIdentifier,
    };
  }
  return updatedProduct;
}
function ProductRowInput(props: {
  product: PenguinProduct;
  productPrices: PenguinProductPrices;
  setProducts: any;
  products: PenguinProduct[];
  deleteProduct: (product: PenguinProduct) => void;
  subscriptionTerms: number;
}) {
  const { product, productPrices, setProducts, products, subscriptionTerms } =
    props;
  const { editMode } = usePricingFlowContext<PenguinPricingFlow>();

  const [volumeType, setVolumeType] = useState<RampUpTypeIdentifier>(
    product.rampType ?? 'fixed',
  );
  const volumeRef = useRef<HTMLInputElement>(null);

  let transactionSizeDetail = product.transactionSize ? (
    <span className="text-xs text-gray-600">
      Avg. transaction size: {formatCurrencyValue(product.transactionSize)}
    </span>
  ) : null;

  const volumeRampUpMonthsForLinearAndLinearQuarterly = Math.min(
    subscriptionTerms,
    DEFAULT_VOLUME_RAMP_UP_MONTHS,
  );

  // Pull in volume suggestion if relevant
  let { volumeSuggestion, setVolumeSuggestion, volumeSuggestionProducts } =
    useVolumeSuggestionsContext();

  let isSuggestableProduct = volumeSuggestionProducts?.includes(product.name);

  useEffect(() => {
    // Set volume suggestion if product already has volume
    if (isSuggestableProduct && product.volume !== undefined) {
      setVolumeSuggestion(product.volume.value);
    }
  }, [isSuggestableProduct, product.volume, setVolumeSuggestion]);

  let { volume: volumeValue } = ensureProductHasVolume(
    product,
    volumeSuggestion,
    volumeSuggestionProducts,
  );

  const productPrice = productPrices[product.id];
  if (isNil(productPrice)) {
    return null;
  }
  const pricingInfo = productPrice.currentPricingCurve.pricingInformation;

  return (
    <tr>
      <td className="flex h-full flex-col gap-1 border-r border-t border-gray-200 px-6 py-4 text-sm font-medium text-gray-900">
        {productPrice.name}

        <div className="text-xs text-gray-600">
          {productPrice['unitDefinition'] ?? 'N/A'}
        </div>
        <div className="text-xs text-gray-600">{transactionSizeDetail}</div>
      </td>

      <td className="h-full whitespace-nowrap border-r border-t border-gray-200 p-0 text-sm font-medium text-gray-500">
        {pricingInfo.unpriceable || productPrice.fixedVolume !== undefined ? (
          <div className="px-6 py-4">N/A</div> // N/A means "No ramp" aka "fixed", or unpriceable
        ) : (
          <SelectInput
            name="volume_type"
            value={volumeType}
            onChange={(e) => {
              const newRampType: RampUpTypeIdentifier = e.target
                .value as RampUpTypeIdentifier;
              setVolumeType(newRampType);
              setProducts(
                products.map((p: any) => {
                  if (p.id === product.id) {
                    return updateProduct({
                      product: p,
                      subscriptionTerms: subscriptionTerms,
                      rampUpTypeIdentifier: newRampType,
                      volume: { type: 'count', value: volumeValue.value }, // Set the volume to whatever is what's shown to the user in the volume input field
                    });
                  }
                  return p;
                }),
              );
            }}
            disabled={!editMode}
            selectClassName="font-medium"
          >
            <option value={'fixed'}>No ramp</option>
            <option value={'linear_quarterly'}>
              Quarterly linear ramp (
              {volumeRampUpMonthsForLinearAndLinearQuarterly} months)
            </option>
            <option value={'linear'}>
              Monthly linear ramp (
              {volumeRampUpMonthsForLinearAndLinearQuarterly} months)
            </option>
            <option value={'custom'}>
              Custom ramp ({subscriptionTerms} months)
            </option>
          </SelectInput>
        )}
      </td>
      <td
        className="h-full w-full whitespace-nowrap border-r border-t border-gray-200 p-0 text-sm text-gray-500"
        onClick={() => {
          if (volumeRef.current !== null) {
            volumeRef.current.focus();
          }
        }}
      >
        {/* should be input field for volume  */}
        {pricingInfo.unpriceable || productPrice.fixedVolume !== undefined ? (
          <span className="pl-3">N/A</span> // N/A means volume = 1, unless it's unpriceable (then volume = 0)
        ) : (
          <EditableIndicator>
            <FormattedNumberField
              ref={volumeRef}
              type="text"
              value={volumeValue.value}
              required={true}
              numberDecimals={0}
              isSuggested={
                product.volume === undefined &&
                isSuggestableProduct &&
                volumeValue.value !== 0
              }
              disabled={!editMode}
              className="cursor-pointer border-none bg-transparent text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
              data-volume-editable
              onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                volumeRef,
                'data-volume-editable',
              )}
              updateValue={(value: number) => {
                setProducts(
                  products.map((p: any) => {
                    if (p.id === product.id) {
                      return updateProduct({
                        product: p,
                        subscriptionTerms: subscriptionTerms,
                        volume: { type: 'count', value },
                        volumeSuggestionProducts,
                        setVolumeSuggestion,
                        volumeSuggestion,
                      });
                    }
                    return p;
                  }),
                );
              }}
            />
          </EditableIndicator>
        )}
      </td>
      <td className="whitespace-nowrap border-t border-gray-200 px-6 py-4 text-right text-sm font-medium">
        <button
          type="button"
          tabIndex={-1}
          className={classNames(
            'flex items-center text-fuchsia-900 hover:text-fuchsia-950',
            !editMode && 'opacity-0',
          )}
          onClick={(e) => {
            e.preventDefault();
            props.deleteProduct(product);
          }}
          disabled={!editMode}
        >
          <TrashIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </td>
    </tr>
  );
}
