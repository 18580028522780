import { datadogRum } from '@datadog/browser-rum';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import api from 'src/api';
import { useModal } from 'src/components/Modal';
import { useToast } from 'src/components/Toast';
import { classNames } from 'src/dashboard/App';
import backgroundLines from 'src/images/background-lines.svg';
import fadedCircleBg from 'src/images/faded-circle-bg.svg';
import logoDealopsTarget from 'src/images/logos/dealops-target.svg';
import { unreachable } from 'src/typeUtils';
import { User } from '../../../../types';
import { usePricingFlowContext } from '../../PricingFlow';
import { PricingFlowStage } from '../../types';
import {
  Currency,
  CurrencyValueFlat,
  DEFAULT_CURRENCY,
} from '../../types_common/price';
import {
  MonthlyMinProductCode,
  PenguinAdditionalData,
  PenguinCustomStageType,
  PenguinOpportunityData,
  PenguinPricebookName,
  PenguinPricingFlow,
  PenguinProduct,
  PenguinSupportedCurrency,
  PENGUIN_SUPPORTED_CURRENCIES,
  pricebook2IdToName,
} from '../penguin_types';
import { getVolumeRampUpMonthsForLinear } from '../Steps/Step3VolumeSelection';
import {
  getOpportunityOverviewBarFields,
  getOpportunitySFDCUrl,
} from './OpportunityOverviewBar';

type PenguinPricingFlowNotStartedPageProps = {
  validateStep: (pricingFlow: PenguinPricingFlow) => {
    value: boolean;
    error: string | null;
  };
  user: User;
};

/**
 *
 * @param startDate 'YYYY-MM-DD'
 * @returns true if the date string converted to UTC date is the 28-31st of the month
 */
function isDateStringAtMonthEnd(startDate: string): boolean {
  const date = new Date(
    Date.UTC(
      parseInt(startDate.substring(0, 4), 10), // Year
      parseInt(startDate.substring(5, 7), 10) - 1, // Month (0-indexed)
      parseInt(startDate.substring(8, 10), 10), // Day
    ),
  );

  const dayOfMonth = date.getUTCDate();

  return (
    dayOfMonth === 28 ||
    dayOfMonth === 29 ||
    dayOfMonth === 30 ||
    dayOfMonth === 31
  );
}

export function validateNotStartedFields(params: {
  startDate: string | undefined;
  subscriptionTerms: number | undefined;
}) {
  const { startDate, subscriptionTerms } = params;

  if (startDate == null || isDateStringAtMonthEnd(startDate)) {
    return {
      value: false,
      error: 'Please select a start date that is not end of month (28th-31st)',
    };
  }
  if (
    subscriptionTerms == null ||
    !Number.isInteger(subscriptionTerms) ||
    subscriptionTerms < 1 ||
    subscriptionTerms > 120
  ) {
    return {
      value: false,
      error: 'Subscription terms should be between 1 and 120 months',
    };
  }
  return {
    value: true,
    error: null,
  };
}

// ##PenguinDefaultMonthlyMinProductCode
// For a currency / pricebook pair, a penguin pricing flow can have many monthly
// minimum options, of which one or many may be added to the quote. The
// "default" monthly minimum product is the one that either:
// - is used to determine monthly minimum tiers when pricing other products (MIN - FLAT)
// - is the only option for that currency + pricebook (MIN - FLATEC)
function defaultMonthlyUsageMinimumProductCode(
  additionalData: PenguinAdditionalData,
): MonthlyMinProductCode {
  switch (additionalData.pricebook) {
    case 'CPQ':
      return 'MIN - FLAT';
    case 'Partnership':
      switch (additionalData.quoteCurrency) {
        case 'USD':
          return 'MIN - FLAT';
        case 'EUR':
        case 'GBP':
        case 'CAD':
          return 'MIN - FLATEC';
        default:
          unreachable(additionalData.quoteCurrency);
      }
    default:
      unreachable(additionalData.pricebook);
  }
}

function getDefaultPricebookName(OpportunityType: string | null) {
  if (OpportunityType === 'Partnership') {
    return 'Partnership';
  }
  return 'CPQ';
}
const PenguinPricingFlowNotStartedPage = (
  props: PenguinPricingFlowNotStartedPageProps,
) => {
  const { pricingFlow, updateFlow, editMode, setStage } =
    usePricingFlowContext<PenguinPricingFlow>();

  const fields = getOpportunityOverviewBarFields(
    pricingFlow.opportunityData as PenguinOpportunityData,
  );

  const [subscriptionTerms, setSubscriptionTerms] = useState<
    number | undefined
  >(pricingFlow.additionalData?.subscriptionTerms ?? undefined);
  const [startDate, setStartDate] = useState<string | undefined>(
    pricingFlow.additionalData?.startDate ?? undefined,
  );
  const [quoteCurrency, setQuoteCurrency] = useState<PenguinSupportedCurrency>(
    pricingFlow.additionalData?.quoteCurrency ??
      pricingFlow.opportunityData.CurrencyIsoCode ??
      DEFAULT_CURRENCY,
  );
  const [pricebook, setPricebook] = useState<PenguinPricebookName>(
    pricingFlow.additionalData?.pricebook ??
      pricebook2IdToName(
        pricingFlow.opportunityData.Opportunity_Pricebook2Id,
      ) ??
      getDefaultPricebookName(pricingFlow.opportunityData.Opportunity_Type),
  );
  const [previousPricingFlows, setPreviousPricingFlows] = useState<
    PenguinPricingFlow[]
  >([]);
  const { showToast } = useToast();
  const [showTemplates, setShowTemplates] = useState<Boolean>(false);

  async function fetchPreviousPricingFlows() {
    let response = await api.get(
      `pricingFlows?matchingPricingFlowId=${pricingFlow.id}`,
    );
    if (response.data) {
      setPreviousPricingFlows(
        response.data.filter((flow: PenguinPricingFlow) => {
          return flow.id !== pricingFlow.id && !isNil(flow.additionalData);
        }),
      );
    }
  }

  useEffect(() => {
    fetchPreviousPricingFlows();
  }, []);

  function setArrayLengthFillWithNull(
    x: (CurrencyValueFlat | null)[],
    newLength: number,
  ): (CurrencyValueFlat | null)[] {
    // If the new length is less than or equal to the current length, slice the array
    if (newLength <= x.length) {
      return x.slice(0, newLength);
    }

    // If the new length is greater, create a new array with the additional elements filled with null
    const additionalElements = new Array(newLength - x.length).fill(null);
    return [...x, ...additionalElements];
  }

  function setArrayLengthFillWithNumber(
    x: number[],
    newLength: number,
    value: number,
  ): number[] {
    // If the new length is less than or equal to the current length, slice the array
    if (newLength <= x.length) {
      return x.slice(-newLength, x.length);
    }

    // If the new length is greater, create a new array with the additional elements filled with null
    const additionalElements = new Array(newLength - x.length).fill(value);
    return [...x, ...additionalElements];
  }

  function validate() {
    const { value, error } = validateNotStartedFields({
      startDate,
      subscriptionTerms,
    });
    if (!value) {
      if (!error) {
        throw new Error('Validation failed but error message is not set');
      }
      showToast({
        title: error,
        subtitle: '',
        type: 'error',
      });
    }
    return value;
  }

  const startPricingFlowOrShowTemplates = async () => {
    if (!validate()) return;
    const { valid, wipeProducts } =
      await confirmQuoteCurrencyAndPricebookChange();
    if (!valid) return;

    let newProducts = wipeProducts ? [] : pricingFlow.products;
    const pricingFlowValidForTemplates = previousPricingFlows.filter(
      // You can only clone templates that match the quote currency and pricebook
      (flow) =>
        flow.additionalData?.quoteCurrency === quoteCurrency &&
        flow.additionalData?.pricebook === pricebook,
    );
    if (
      pricingFlowValidForTemplates.length > 0 &&
      (!newProducts || newProducts?.length === 0)
    ) {
      setShowTemplates(true);
    } else {
      startPricingFlow({ pricingFlowToCloneId: null, wipeProducts });
    }
  };

  const { showModal, hideModal } = useModal();

  function confirmQuoteCurrencyAndPricebookChange(): Promise<{
    valid: boolean;
    wipeProducts: boolean;
  }> {
    if (!pricingFlow.products || pricingFlow.products?.length === 0)
      return Promise.resolve({ valid: true, wipeProducts: false }); // No products added yet, don't need to confirm with user
    let showConfirmationModal = false;
    let title = '';
    let message = '';
    const quoteCurrencyChanged =
      quoteCurrency !== pricingFlow.additionalData?.quoteCurrency;
    const pricebookChanged =
      pricebook !== pricingFlow.additionalData?.pricebook;
    if (quoteCurrencyChanged && pricebookChanged) {
      showConfirmationModal = true;
      title = 'Change quote currency and pricebook';
      message = `You are changing the quote currency from ${pricingFlow.additionalData?.quoteCurrency} to ${quoteCurrency} and the pricebook from ${pricingFlow.additionalData?.pricebook} to ${pricebook}. Are you sure you want to proceed? This will remove all currently configured products and change the available product catalog.`;
    } else if (quoteCurrencyChanged) {
      showConfirmationModal = true;
      title = 'Change quote currency';
      message = `You are changing the quote currency from ${pricingFlow.additionalData?.quoteCurrency} to ${quoteCurrency}. Are you sure you want to proceed? This will remove all currently configured products and change the available product catalog.`;
    } else if (pricebookChanged) {
      showConfirmationModal = true;
      title = 'Change pricebook';
      message = `You are changing the pricebook from ${pricingFlow.additionalData?.pricebook} to ${pricebook}. Are you sure you want to proceed? This will remove all currently configured products and change the available product catalog.`;
    }
    if (showConfirmationModal) {
      return new Promise((resolve) => {
        showModal({
          title,
          children: (
            <div className="p-0 text-sm text-slate-900">
              <p>{message}</p>
              <div className="h-4" />

              <div className="flex flex-row gap-2 justify-end">
                <button
                  type="button"
                  className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    resolve({ valid: false, wipeProducts: false });
                    hideModal();
                  }}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="rounded bg-fuchsia-900 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
                  onClick={async () => {
                    resolve({ valid: true, wipeProducts: true });
                    hideModal();
                  }}
                >
                  Confirm change
                </button>
              </div>
            </div>
          ),
        });
      });
    }

    return Promise.resolve({ valid: true, wipeProducts: false });
  }

  const startPricingFlow = async (params: {
    pricingFlowToCloneId: string | null;
    wipeProducts: boolean;
  }) => {
    const { pricingFlowToCloneId, wipeProducts } = params;
    if (validate()) {
      try {
        if (subscriptionTerms == null || startDate == null) {
          throw new Error(
            'subscriptionTerms and startDate should not be null, validation was incorrectly passed',
          );
        }

        if (!editMode) {
          setStage({
            stage: PricingFlowStage.ADD_PRODUCTS,
            customStage: PenguinCustomStageType.MODEL_SELECTION,
          });
          return;
        }
        let newProducts = wipeProducts ? [] : pricingFlow.products;

        let newMinimumRampUp = pricingFlow.additionalData?.minimumRampUp;
        let manualQuote = wipeProducts ? null : pricingFlow.manualQuote;
        let oldSubscriptionTerms =
          pricingFlow.additionalData?.subscriptionTerms;
        let additionalMonthlyMinimumProductCodes = wipeProducts
          ? [
              defaultMonthlyUsageMinimumProductCode({
                // Reset to the default monthly minimum for the new quote currency and pricebook
                ...pricingFlow.additionalData,
                quoteCurrency,
                pricebook,
              }),
            ]
          : pricingFlow.additionalData?.additionalMonthlyMinimumProductCodes;

        if (pricingFlowToCloneId) {
          const flowToClone = previousPricingFlows.find(
            (flow) => flow.id === pricingFlowToCloneId,
          );
          if (flowToClone) {
            newProducts = flowToClone.products;
            manualQuote = flowToClone.manualQuote;
            oldSubscriptionTerms = flowToClone.additionalData.subscriptionTerms;
            additionalMonthlyMinimumProductCodes =
              flowToClone.additionalData.additionalMonthlyMinimumProductCodes;
          }
        }

        if (oldSubscriptionTerms !== subscriptionTerms) {
          // If we change the subscription terms but we already configured some products with volume ramp ups, or monthly minimum ramp ups, we need to adjust the products to account for the change
          // for each product, modify volume ramp ups so the config matches the subscription terms
          newProducts = newProducts?.map((product: PenguinProduct) => {
            switch (product.rampType) {
              case 'custom':
                product.customRampUp = setArrayLengthFillWithNumber(
                  product.customRampUp,
                  subscriptionTerms,
                  product.customRampUp[product.customRampUp.length - 1],
                );
                break;
              case 'linear_quarterly':
              case 'linear':
                product.linearRampUpConfig = {
                  ...product.linearRampUpConfig,
                  months: getVolumeRampUpMonthsForLinear(subscriptionTerms),
                };
                break;
            }
            return product;
          });
          // for monthly minimum ramp, modify the number of months to match the subscription terms
          newMinimumRampUp = pricingFlow.additionalData?.minimumRampUp
            ? setArrayLengthFillWithNull(
                pricingFlow.additionalData?.minimumRampUp,
                subscriptionTerms,
              )
            : null;
        }
        updateFlow({
          ...pricingFlow,
          additionalData: {
            ...(pricingFlow.additionalData as PenguinAdditionalData),
            customStage: pricingFlowToCloneId
              ? PenguinCustomStageType.PRICING_ADJUSTMENTS
              : PenguinCustomStageType.MODEL_SELECTION,
            subscriptionTerms,
            startDate,
            minimumRampUp: newMinimumRampUp,
            quoteCurrency,
            pricebook,
            productCategories: wipeProducts
              ? []
              : pricingFlow.additionalData?.productCategories,
            additionalMonthlyMinimumProductCodes,
          },
          products: newProducts,
          stage: pricingFlowToCloneId
            ? PricingFlowStage.CALCULATE_PRICE
            : PricingFlowStage.ADD_PRODUCTS,
          manualQuote: manualQuote,
          cloneFromPricingFlowId: pricingFlowToCloneId ?? undefined,
        });
      } catch (e) {
        console.error(e);
        datadogRum.addError(e);
        showToast({
          title: 'Something went wrong',
          subtitle: 'Unable to use template, please start from scratch',
          type: 'error',
        });
      }
    }
  };

  return (
    <div
      className="relative flex flex-col items-center justify-center bg-repeat-x"
      style={{ backgroundImage: `url(${backgroundLines})` }}
    >
      {/* Dealops target logo */}
      <div className="mt-20 h-24 w-24">
        <img
          className="absolute h-24 w-24"
          src={fadedCircleBg}
          alt="faded circle"
        />
        <div className="absolute ml-5 mt-5 flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
          <img className="h-7 w-7" src={logoDealopsTarget} alt="Dealops" />
        </div>
      </div>

      {/** Heading */}
      <h1 className="mx-auto max-w-7xl px-4 pt-6 text-center text-2xl font-semibold sm:px-6 lg:px-8">
        {showTemplates
          ? 'Use one of your previous quotes as a template or start from scratch'
          : 'Pricing calculator'}
      </h1>
      <p className="text-l mx-auto max-w-7xl px-4 pt-2 text-center text-gray-700 sm:px-6 lg:px-8">
        {showTemplates
          ? "If you select one of your previous quotes as a template, we'll copy over product selection and manually configured prices."
          : "Let's work on pricing for this opportunity!"}
      </p>

      {/** Opportunity details */}
      {!showTemplates ? (
        <div className="mb-20 mt-10 w-[400px] rounded-2xl border border-gray-200 bg-white">
          <div className="flex flex-col items-center justify-center gap-1 px-4 py-4">
            <span className="text-xs text-gray-500">
              Opportunity #{pricingFlow.sfdcOpportunityId}
            </span>
            <span className="text-2xl text-gray-900">
              {pricingFlow.sfdcOpportunityName}
            </span>
          </div>
          <div className="bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
            DETAILS
          </div>
          <div className="flex flex-col gap-2.5 px-4 py-4">
            {fields.map((field) => (
              <div
                key={field.name}
                className="flex w-full flex-row justify-between"
              >
                <span className="text-sm text-gray-500">{field.name}</span>
                <span className="text-sm font-medium text-gray-900">
                  {field.value}
                </span>
              </div>
            ))}
          </div>
          <div className="mt-5 bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
            TERMS
          </div>
          <div className="flex flex-row justify-between px-4 py-4">
            <div className="flex flex-col gap-2">
              <span className="text-sm text-gray-900"> Start date</span>
              <input
                type="date"
                className="text-md w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent"
                id="startDate"
                value={startDate}
                disabled={!editMode}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-sm text-gray-900"> Subscription terms</span>
              <input
                className="text-md w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent"
                placeholder={'in months'}
                type="number"
                min="1"
                step="1"
                id="subscriptionTerms"
                value={subscriptionTerms}
                disabled={!editMode}
                onChange={(e) =>
                  setSubscriptionTerms(parseInt(e.target.value) || undefined)
                }
              />
            </div>
          </div>
          <div className="mt-5 bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
            INPUTS
          </div>
          <div className="flex flex-row justify-between px-4 py-4">
            <div className="flex flex-col gap-2">
              <span className="text-sm text-gray-900">Quote currency</span>
              <select
                value={quoteCurrency}
                onChange={(e) =>
                  setQuoteCurrency(e.target.value as PenguinSupportedCurrency)
                }
                className="text-md w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent"
                disabled={!editMode}
              >
                {PENGUIN_SUPPORTED_CURRENCIES.map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-sm text-gray-900">Pricebook</span>
              <select
                value={pricebook}
                onChange={(e) =>
                  setPricebook(e.target.value as PenguinPricebookName)
                }
                className="text-md w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent"
                disabled={!editMode}
              >
                <option value={'CPQ'}>CPQ</option>
                <option value={'Partnership'}>Partnership</option>
              </select>
            </div>
          </div>
          {/** Buttons */}
          <div className="flex items-center gap-12 border-t border-gray-200 px-4 py-4">
            <button
              onClick={() =>
                window.open(
                  getOpportunitySFDCUrl(pricingFlow),
                  '_blank',
                  'noopener,noreferrer',
                )
              }
              className="flex w-full flex-row items-center gap-2 rounded-lg px-3 py-2 text-sm font-bold text-fuchsia-900 hover:text-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
              role="link"
              tabIndex={0}
            >
              See in SFDC
              <ArrowTopRightOnSquareIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
            <button
              onClick={async () => {
                await startPricingFlowOrShowTemplates();
              }}
              className={classNames(
                'w-full rounded-lg px-3 py-2 text-sm font-bold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-fuchsia-900 hover:bg-fuchsia-800 focus-visible:outline-fuchsia-900',
              )}
            >
              Start
            </button>
          </div>
        </div>
      ) : (
        <PreviousPricingFlows
          pricingFlows={previousPricingFlows}
          pricingFlow={pricingFlow}
          startPricingFlow={startPricingFlow}
          setShowTemplates={setShowTemplates}
          quoteCurrency={quoteCurrency}
          pricebook={pricebook}
        />
      )}
    </div>
  );
};

const PreviousPricingFlows = (props: {
  pricingFlow: PenguinPricingFlow; // DO NOT USE THIS PRICING FLOW for anything except id– it is not updated
  startPricingFlow: (params: {
    pricingFlowToCloneId: string | null;
    wipeProducts: boolean;
  }) => void;
  pricingFlows: PenguinPricingFlow[];
  setShowTemplates: (value: Boolean) => void;
  quoteCurrency: Currency;
  pricebook: string;
}) => {
  const { pricingFlow, startPricingFlow, setShowTemplates } = props;
  const pricingFlows = props.pricingFlows.filter(
    (flow) =>
      flow.additionalData?.quoteCurrency === props.quoteCurrency &&
      flow.additionalData?.pricebook === props.pricebook,
  );

  const [showAll, setShowAll] = useState<Boolean>(false);

  const initialNumberOfQuotes = 3;
  const flowsToShow = showAll
    ? pricingFlows
    : pricingFlows.slice(0, initialNumberOfQuotes);
  return (
    <div className="mb-20 mt-10 w-[500px] divide-y rounded-2xl border border-gray-200  text-sm">
      <div className="flex flex-col items-center justify-center gap-1 px-4 py-4">
        <span className="text-xs text-gray-500">
          Opportunity #{pricingFlow.sfdcOpportunityId}
        </span>
        <span className="text-center text-2xl text-gray-900">
          {pricingFlow.sfdcOpportunityName}
        </span>
      </div>
      <div className="bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
        PREVIOUS QUOTES
      </div>

      {flowsToShow.map((flow: PenguinPricingFlow, index) => (
        <div
          key={index}
          className="flex items-center justify-between gap-12 px-4 py-4"
        >
          <div className="flex-1 truncate">
            <a
              className="hover:text-fuchsia-900"
              href={`/app/pricingflow?opportunity=${flow.sfdcOpportunityId}`}
              target="_blank"
              rel="noreferrer"
              title={flow.sfdcOpportunityName ?? ''}
            >
              {flow.sfdcOpportunityName}
            </a>
          </div>
          <div>
            <button
              onClick={() => {
                startPricingFlow({
                  pricingFlowToCloneId: flow.id,
                  wipeProducts: true,
                });
              }}
              className="flex inline-flex items-center justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
            >
              Use as template
              <DocumentDuplicateIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        </div>
      ))}

      {!showAll && initialNumberOfQuotes < pricingFlows.length && (
        <div className="flex justify-center py-4">
          <button
            onClick={() => setShowAll(true)}
            className="inline-flex items-center justify-center rounded-lg border border-gray-200 px-5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          >
            Show all previous quotes ({pricingFlows.length})
          </button>
        </div>
      )}

      <div className="flex items-center gap-12 border-t border-gray-200 px-4 py-4">
        <button
          onClick={() => setShowTemplates(false)}
          className="flex inline-flex items-center  justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          role="link"
          tabIndex={0}
        >
          Back
        </button>
        <div className="ml-auto">
          <button
            onClick={() =>
              startPricingFlow({
                pricingFlowToCloneId: null,
                wipeProducts: true,
              })
            }
            className=" rounded-lg bg-fuchsia-900 px-5 py-2 text-sm font-bold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          >
            Start from scratch
          </button>
        </div>
      </div>
    </div>
  );
};

export default PenguinPricingFlowNotStartedPage;
