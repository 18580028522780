import { datadogRum } from '@datadog/browser-rum';
import { produce, setAutoFreeze } from 'immer';
import { isFinite, isNil, round } from 'lodash';
import api from 'src/api';
import { formatCurrency, formatNumber } from 'src/utils/formatters';
import {
  Count,
  CurrencyValue,
  CurrencyValueFlat,
  CurrencyValueFlatAndPercent,
  CurrencyValuePercent,
  CurrencyValueType,
  DerivedValue,
  Minimum,
  QuotePrice,
  Tier,
  ZERO_FLAT,
  ZERO_PERCENT,
} from '../types_common/price';
import {
  AlpacaAdditionalData,
  AlpacaCategoryName,
  AlpacaCurrentPricingCurves,
  AlpacaDerivedAggregations,
  AlpacaIssuingConfig,
  AlpacaPricingFlow,
  AlpacaProduct,
  AlpacaProductPrices,
  AlpacaSupportedCurrency,
  AlpacaTFxPricingInformation,
  ALPACA_DEFAULT_CURRENCY,
  ALPACA_SUPPORTED_CURRENCIES,
  ForexRates,
  MonthlySubscriptionFee,
} from './alpaca_types';
import {
  currencyForIssuingProduct,
  getVolumeForIssuingProduct,
} from './Components/AlpacaIssuingTable';

export function roundQuotePrice<T extends QuotePrice = QuotePrice>(cv: T) {
  switch (cv.type) {
    case CurrencyValueType.FLAT:
    case CurrencyValueType.PERCENT:
      return { ...cv, value: round(cv.value, 2) };
    case CurrencyValueType.FLAT_AND_PERCENT:
      return { ...cv, flat: round(cv.flat, 2), percent: round(cv.percent, 2) };
    case 'tiered':
    case 'ramped':
      // To stay true to its name, this function should probably round all the
      // currency values in the tiers? However, in practice, this function is
      // only called when converting from a computed sticker price or cost into
      // the quote price field, which will always be one of the CurrencyValue
      // types
      datadogRum.addError(
        new Error(
          `did not expect to call roundQuotePrice on type ${cv.type}, not doing anything`,
        ),
      );
      return cv;
    default:
      const typecheck: never = cv;
      datadogRum.addError(`unexpected currency value type ${typecheck}`);
      return cv;
  }
}

export function nanToZero(number: number | null | undefined) {
  if (isNil(number) || !isFinite(number)) {
    return 0;
  }
  return number;
}

function sortTiers(tiers: Tier[]) {
  return tiers.sort((a, b) => {
    return a.minimum.value - b.minimum.value;
  });
}

function mean(numbers: number[]) {
  if (numbers.length === 0) {
    return 0;
  }
  return numbers.reduce((a, b) => a + b) / numbers.length;
}
function sum(numbers: number[]) {
  if (numbers.length === 0) {
    return 0;
  }
  return numbers.reduce((partialSum, val) => partialSum + val, 0);
}

function getVolume(
  product: AlpacaProduct,
  pricingFlow: AlpacaPricingFlow,
): CurrencyValueFlat | null {
  switch (product.categoryName) {
    case 'Treasury FX':
    case 'Collections':
    case 'Global Accounts':
    case 'Payouts':
      const volume = product.volume;
      const quoteCurrency = pricingFlow.additionalData.quoteCurrency;
      return isNil(volume)
        ? null
        : {
            type: CurrencyValueType.FLAT,
            value: volume,
            currency: quoteCurrency,
          };
    case 'Issuing':
      return getVolumeForIssuingProduct(product, pricingFlow).volume;
    default:
      const typecheck: never = product;
      datadogRum.addError(`unexpected category name ${typecheck}`);
      return ZERO_FLAT(pricingFlow.additionalData.quoteCurrency);
  }
}

export function getSumCount(countsRaw: (Count | null)[]): DerivedValue<Count> {
  const counts = countsRaw.filter((cv): cv is Count => !isNil(cv));
  if (counts.length > 0) {
    const sumVal = sum(counts.map((cv) => cv.value));
    return {
      type: 'count',
      value: sumVal,
      provenance: counts.every((cv) => cv.value === 0)
        ? `sum(0)`
        : `sum(${counts
            .filter((cv) => cv.value !== 0)
            .map((cv) => cv.value)
            .join(', ')})`,
    };
  } else {
    return {
      type: 'count',
      value: 0,
      provenance: `sum of nothing`,
    };
  }
}

export function getSumCurrencyValue({
  currencyValuesRaw,
  quoteCurrency,
  pricingFlow,
}: {
  currencyValuesRaw: (CurrencyValueFlat | null)[];
  quoteCurrency: AlpacaSupportedCurrency;
  pricingFlow: AlpacaPricingFlow;
}): DerivedValue<CurrencyValueFlat> {
  const currencyValues = currencyValuesRaw
    .filter((cv): cv is CurrencyValueFlat => !isNil(cv))
    .map((cv) => {
      return convertCurrencyValueForex(cv, quoteCurrency, pricingFlow);
    });
  if (currencyValues.length > 0) {
    const sumVal = sum(currencyValues.map((cv) => cv.value));
    return {
      type: CurrencyValueType.FLAT,
      value: sumVal,
      currency: currencyValues[0].currency,
      provenance: currencyValues.every((cv) => cv.value === 0)
        ? `sum(${formatCurrencyValue(currencyValues[0])})`
        : `sum(${currencyValues
            .filter((cv) => cv.value !== 0)
            .map((cv) => formatCurrencyValue(cv))
            .join(', ')})`,
    };
  } else {
    return {
      type: CurrencyValueType.FLAT,
      value: 0,
      currency: quoteCurrency,
      provenance: `sum of nothing`,
    };
  }
}

/**
 * 
 * @param currencyValues CurrencyValue[] with all the same currency
 * @returns If all the currencyValues are of the same type, we can just average them
  Otherwise the type will be CurrencyValueType.FLAT_AND_PERCENT and we will average both components
 */
export function getAverageCurrencyValue(
  currencyValuesRaw: CurrencyValue[],
  quoteCurrency: AlpacaSupportedCurrency,
  pricingFlow: AlpacaPricingFlow,
): DerivedValue<CurrencyValue> | null {
  const currencyValues = currencyValuesRaw.map((cv) =>
    convertCurrencyValueForex(cv, quoteCurrency, pricingFlow),
  );
  if (currencyValues.length === 0) {
    return null;
  }
  if (
    currencyValues.every(
      (c): c is CurrencyValuePercent => c.type === CurrencyValueType.PERCENT,
    )
  ) {
    return {
      type: CurrencyValueType.PERCENT,
      value: mean(currencyValues.map((c) => c.value)),
      provenance: `mean(${currencyValues.map((c) => formatCurrencyValue(c)).join(', ')})`,
    };
  }

  // Check that every CurrencyValue that has a currency, has the SAME currency
  const currencies = currencyValues
    .filter(
      (c): c is CurrencyValueFlat | CurrencyValueFlatAndPercent =>
        c.type === CurrencyValueType.FLAT ||
        c.type === CurrencyValueType.FLAT_AND_PERCENT,
    )
    .map((c) => c.currency);
  // Check all the currencies are the same
  const currency = currencies[0];
  if (!currencies.every((c) => c === currency)) {
    datadogRum.addError(`Currencies are not the same: ${currencies}`);
    return null;
  }

  const flatMeanComponents = currencyValues
    .map((c) => {
      switch (c.type) {
        case CurrencyValueType.FLAT:
          return c.value;
        case CurrencyValueType.PERCENT:
          return null;
        case CurrencyValueType.FLAT_AND_PERCENT:
          return c.flat;
        default:
          const typecheck: never = c;
          datadogRum.addError(`invalid currency type ${typecheck}`);
          return null;
      }
    })
    .filter((n): n is number => !isNil(n));
  const flatMean = mean(flatMeanComponents);
  const percentMeanComponents = currencyValues
    .map((c) => {
      switch (c.type) {
        case CurrencyValueType.FLAT:
          return null;
        case CurrencyValueType.PERCENT:
          return c.value;
        case CurrencyValueType.FLAT_AND_PERCENT:
          return c.percent;
        default:
          const typecheck: never = c;
          datadogRum.addError(`invalid currency type ${typecheck}`);
          return null;
      }
    })
    .filter((n): n is number => !isNil(n));
  const percentMean = mean(percentMeanComponents);
  const proto = currencyValues[0];
  if (proto.type === CurrencyValueType.FLAT) {
    return {
      type: CurrencyValueType.FLAT,
      value: flatMean,
      currency: proto.currency,
      provenance: `mean(${flatMeanComponents.map((n) => round(n, 2)).join(', ')})`,
    };
  } else {
    // CurrencyValueType.PERCENT is handled above
    return {
      type: CurrencyValueType.FLAT_AND_PERCENT,
      flat: flatMean,
      percent: percentMean,
      currency,
      provenance: `flatMean: mean(${flatMeanComponents.map((n) => round(n, 2)).join(', ')})\npercentMean: mean(${percentMeanComponents.map((n) => round(n, 2)).join(', ')})`,
    };
  }
}

// @TODO(fayplaid) move to price utils
export const formatCurrencyValue = (
  currencyValue: CurrencyValue | Count | null | undefined,
  numDecimals?: number,
): string => {
  if (isNil(currencyValue)) {
    return 'N/A';
  }
  const numDecimalsOrDefault = numDecimals ?? 2;

  switch (currencyValue.type) {
    case CurrencyValueType.FLAT:
      const flatAmount = Number.isNaN(currencyValue.value)
        ? 0
        : currencyValue.value;
      return formatCurrency({
        amount: round(flatAmount, numDecimalsOrDefault),
        currency: currencyValue.currency,
        minimumFractionDigits: numDecimalsOrDefault,
      });
    case CurrencyValueType.FLAT_AND_PERCENT:
      const flat = Number.isNaN(currencyValue.flat) ? 0 : currencyValue.flat;
      const percent = Number.isNaN(currencyValue.percent)
        ? 0
        : formatNumber(
            round(currencyValue.percent, numDecimalsOrDefault),
            numDecimalsOrDefault,
            true,
          );
      return (
        formatCurrency({
          amount: round(flat, numDecimalsOrDefault),
          currency: currencyValue.currency,
          minimumFractionDigits: numDecimals ?? 2,
        }) +
        ' + ' +
        percent +
        '%'
      );
    case CurrencyValueType.PERCENT:
      let value = currencyValue.value;
      if (Number.isNaN(currencyValue.value)) value = 0;

      return (
        formatNumber(
          round(value, numDecimalsOrDefault),
          numDecimalsOrDefault,
          true,
        ) + '%'
      );
    case 'count':
      return Number.isNaN(currencyValue.value)
        ? formatNumber(0, 0)
        : formatNumber(currencyValue.value, 0);
    default:
      const typecheck: never = currencyValue;
      datadogRum.addError(
        'Unknown currency value type, formatCurrencyValue cannot display',
        { typecheck },
      );
      return 'N/A';
  }
};

export const convertCurrencyValueToNumber = (
  currencyValue: CurrencyValue | Count | null,
): number => {
  if (isNil(currencyValue)) {
    return NaN;
  }

  switch (currencyValue.type) {
    case CurrencyValueType.FLAT:
      return currencyValue.value;
    case CurrencyValueType.FLAT_AND_PERCENT:
      return NaN;
    case CurrencyValueType.PERCENT:
      return currencyValue.value;
    case 'count':
      return currencyValue.value;
    default:
      return NaN;
  }
};

export function convertQuotePrice(
  quotePrice: QuotePrice,
  quoteCurrency: AlpacaSupportedCurrency,
  pricingFlow: AlpacaPricingFlow,
): QuotePrice {
  switch (quotePrice.type) {
    case CurrencyValueType.FLAT:
    case CurrencyValueType.PERCENT:
    case CurrencyValueType.FLAT_AND_PERCENT:
      return convertCurrencyValueForex(quotePrice, quoteCurrency, pricingFlow);
    case 'tiered':
      return {
        ...quotePrice,
        type: 'tiered',
        tiers: quotePrice.tiers.map((tier) => ({
          ...tier,
          currencyValue: convertCurrencyValueForex(
            tier.currencyValue,
            quoteCurrency,
            pricingFlow,
          ),
          minimum:
            tier.minimum.type === 'count'
              ? tier.minimum
              : (convertCurrencyValueForex(
                  tier.minimum,
                  quoteCurrency,
                  pricingFlow,
                ) as Minimum), // @TODO(fay) see if you can fix this
        })),
      };
    case 'ramped':
      return {
        ...quotePrice,
        rampValues: quotePrice.rampValues.map((oldVal) =>
          convertCurrencyValueForex(oldVal, quoteCurrency, pricingFlow),
        ),
      };
    default:
      const typecheck: never = quotePrice;
      datadogRum.addError(
        'Unknown quote price type, convertQuotePrice cannot convert',
        { typecheck },
      );
      return quotePrice;
  }
}
export function convertCurrencyValueForex<
  T extends CurrencyValue = CurrencyValue,
>(
  currencyValue: T,
  quoteCurrency: AlpacaSupportedCurrency,
  pricingFlow: AlpacaPricingFlow,
): T {
  if (currencyValue.type === CurrencyValueType.PERCENT) {
    // Percent, no conversion needed
    return currencyValue;
  }
  if (currencyValue.currency === quoteCurrency) {
    // Same currency, no conversion needed
    return currencyValue;
  }

  const forexRates = pricingFlow.additionalData.forexRates;
  const conversionRate = forexRates?.[quoteCurrency]?.[currencyValue.currency];
  const conversionRateInverse =
    forexRates?.[currencyValue.currency]?.[quoteCurrency];
  if (conversionRate === undefined && conversionRateInverse === undefined) {
    if (pricingFlow.additionalData.shouldCurrencyValuesBeConsistent) {
      datadogRum.addError(
        `Currency values have been converted but missing forex conversion rate: ${quoteCurrency} ${currencyValue.currency}`,
      );
    }
    // If we can't find a conversion rate, don't convert
    console.log(
      'No conversion rate found for ',
      currencyValue.currency,
      ' to ',
      quoteCurrency,
    );
    return currencyValue;
  }

  switch (currencyValue.type) {
    case CurrencyValueType.FLAT:
      return {
        type: CurrencyValueType.FLAT,
        currency: quoteCurrency,
        value: conversionRate
          ? currencyValue.value / conversionRate
          : currencyValue.value * conversionRateInverse,
      } as T;
    case CurrencyValueType.FLAT_AND_PERCENT:
      return {
        type: CurrencyValueType.FLAT_AND_PERCENT,
        currency: quoteCurrency,
        flat: conversionRate
          ? currencyValue.flat / conversionRate
          : currencyValue.flat * conversionRateInverse,
        percent: currencyValue.percent,
      } as T;
    default:
      const typecheck: never = currencyValue;
      datadogRum.addError(
        'Unknown currency value type, convertCurrencyValueForex cannot convert',
        { typecheck },
      );
      return currencyValue;
  }
}

function getCost(
  product: AlpacaProduct,
  productInfos: AlpacaProductPrices,
  currentPricingCurves: AlpacaCurrentPricingCurves,
  quoteCurrency: AlpacaSupportedCurrency,
  pricingFlow: AlpacaPricingFlow,
): QuotePrice {
  switch (product.categoryName) {
    case 'Treasury FX':
      // #AlpacaTreasuryFxProductCost
      switch (product.subCategory) {
        case 'bucket': {
          const components = product.currencyIds.map((currencyId) => {
            const currency = productInfos[currencyId];
            const pricingInfo = currentPricingCurves[currencyId]
              .pricingInformation as AlpacaTFxPricingInformation;
            return {
              price: pricingInfo.cost,
              name: currency.name,
            };
          });
          return (
            getAverageCurrencyValue(
              components.map((c) => c.price),
              quoteCurrency,
              pricingFlow,
            ) ?? {
              type: CurrencyValueType.PERCENT,
              value: 0,
            }
          );
        }
        case 'pair': {
          const pricingInfo =
            currentPricingCurves[product.buyCurrencyId].pricingInformation;
          return pricingInfo.cost ?? ZERO_PERCENT;
        }
        case 'single_buy': {
          const pricingInfo =
            currentPricingCurves[product.currencyId].pricingInformation;
          return pricingInfo.cost ?? ZERO_PERCENT;
        }
        default:
          const typecheck: never = product;
          datadogRum.addError(`got invalid product subcategory ${typecheck}`);
          return { type: CurrencyValueType.PERCENT, value: 0 };
      }
    case 'Collections':
    case 'Global Accounts':
    case 'Payouts':
      return (
        currentPricingCurves[product.id].pricingInformation.cost ?? ZERO_PERCENT
      );
    case 'Issuing':
      return (
        product.quoteCost ?? product.pricingInformation?.cost ?? ZERO_PERCENT
      );
    default:
      const typecheck: never = product;
      datadogRum.addError(`Invalid category ${typecheck}`);
      return ZERO_PERCENT;
  }
}

const EMPTY_FLAT_CURRENCY_VAL = (quoteCurrency: AlpacaSupportedCurrency) => {
  return {
    type: CurrencyValueType.FLAT as const,
    value: 0,
    currency: quoteCurrency,
    provenance: 'default for no data',
  };
};
const EMPTY_PERCENT_CURRENCY_VAL = {
  type: CurrencyValueType.PERCENT as const,
  value: 0,
  provenance: 'default for no data',
};
export const EMPTY_DERIVED_AGGREGATIONS = (
  quoteCurrency: AlpacaSupportedCurrency,
): AlpacaDerivedAggregations => {
  return {
    estimatedTransactionCount: null,
    estimatedVolume: null,
    grossProfit: EMPTY_FLAT_CURRENCY_VAL(quoteCurrency),
    grossRevenue: EMPTY_FLAT_CURRENCY_VAL(quoteCurrency),
    grossCost: EMPTY_FLAT_CURRENCY_VAL(quoteCurrency),
    profitMargin: EMPTY_PERCENT_CURRENCY_VAL,
    takeRate: null,
  };
};

const computeDerivedAggregationsForQuote = (
  pricingFlow: AlpacaPricingFlow,
): AlpacaDerivedAggregations => {
  const categories = pricingFlow.additionalData?.productCategories;
  if (isNil(categories)) {
    return EMPTY_DERIVED_AGGREGATIONS(pricingFlow.additionalData.quoteCurrency);
  }
  const aggs = categories.map((c) =>
    computeDerivedAggregationsForCategory(c.category, pricingFlow),
  );
  const zero = {
    ...ZERO_FLAT(pricingFlow.additionalData.quoteCurrency),
    provenance: 'N/A',
  };
  const monthlySubscriptionFee = {
    ...(pricingFlow.additionalData.monthlySubscriptionFee?.type === 'tiered'
      ? pricingFlow.additionalData.monthlySubscriptionFee.tiers[
          pricingFlow.additionalData.monthlySubscriptionFee.tiers.length - 1
        ].currencyValue
      : (pricingFlow.additionalData.monthlySubscriptionFee ?? zero)),
    provenance: 'Monthly subscription fee',
  };
  aggs.push({
    estimatedVolume: null,
    estimatedTransactionCount: null,
    grossRevenue: monthlySubscriptionFee,
    grossProfit: monthlySubscriptionFee,
    grossCost: zero,
    profitMargin: {
      type: CurrencyValueType.PERCENT,
      value: 100,
      provenance: `${formatCurrencyValue(monthlySubscriptionFee)} / ${formatCurrencyValue(monthlySubscriptionFee)}`,
    },
    takeRate: null,
  });
  return combineAggregations(
    aggs,
    pricingFlow.additionalData.quoteCurrency,
    pricingFlow,
  );
};

// Synced with server/src/utils/alpaca/opportunity_line_items.ts
export function getActiveIssuingEntities(additionalData: AlpacaAdditionalData) {
  const issuingConfig = additionalData?.issuingConfig;
  const activeIssuingEntityNames = issuingConfig.isMultiCountryIssuingEnabled
    ? issuingConfig.selectedEntities
    : [issuingConfig.currentlyViewingEntity];
  return issuingConfig.entities.filter((e) =>
    activeIssuingEntityNames.includes(e.name),
  );
}

const computeDerivedAggregationsForCategory = (
  category: AlpacaCategoryName,
  pricingFlow: AlpacaPricingFlow,
): AlpacaDerivedAggregations => {
  const allProducts = pricingFlow.products;
  if (isNil(allProducts)) {
    return EMPTY_DERIVED_AGGREGATIONS(pricingFlow.additionalData.quoteCurrency);
  }
  const products = allProducts.filter((p) => p.categoryName === category);
  const defaultAggregations = computeDerivedAggregationsForProducts(
    products,
    pricingFlow,
    pricingFlow.additionalData.quoteCurrency,
  );
  switch (category) {
    case 'Global Accounts':
    case 'Treasury FX':
    case 'Collections':
    case 'Payouts':
      return defaultAggregations;
    case 'Issuing':
      // issuing aggregations are aggregated across selected entities

      // If multi-country issuing is disabled, only use volume from the
      // currently visible entity
      const activeIssuingEntityNames = getActiveIssuingEntities(
        pricingFlow.additionalData,
      ).map((e) => e.name);

      const entities = pricingFlow.additionalData.issuingConfig.entities.filter(
        (entity) => activeIssuingEntityNames.includes(entity.name),
      );

      // est. volume aggregation
      let volumesPerEntity = entities.map(
        (entity) => entity.monthlySpendAtScale,
      );
      const estimatedVolume = getSumCurrencyValue({
        currencyValuesRaw: volumesPerEntity,
        pricingFlow,
        quoteCurrency: pricingFlow.additionalData.quoteCurrency,
      });

      // est. transaction count aggregation
      let transactionCountPerEntity = entities.map(
        (entity) => entity.monthlyTransactionCount,
      );
      // Take rate
      return {
        ...defaultAggregations,
        estimatedVolume,
        estimatedTransactionCount: getSumCount(transactionCountPerEntity),
        takeRate: {
          value: round(
            (100 * defaultAggregations.grossProfit.value) /
              estimatedVolume.value,
            2,
          ),
          type: CurrencyValueType.PERCENT as const,
          concept: 'Monthly gross profit / monthly estimated volume',
          provenance: `${formatCurrencyValue(defaultAggregations.grossProfit)} / ${formatCurrencyValue(defaultAggregations.estimatedVolume)}`,
        },
      };
    case 'Acquiring':
      datadogRum.addError('This category is not yet supported');
      throw new Error('This category is not yet supported');
    default:
      const typecheck: never = category;
      datadogRum.addError(`unexpected category name ${typecheck}`);
      return defaultAggregations;
  }
};
export const combineAggregations = (
  aggregations: AlpacaDerivedAggregations[],
  quoteCurrency: AlpacaSupportedCurrency,
  pricingFlow: AlpacaPricingFlow,
): AlpacaDerivedAggregations => {
  const volumes: CurrencyValueFlat[] = [];
  const txnCounts: Count[] = [];
  const grossProfits = [];
  const grossRevenues = [];
  const grossCosts = [];
  for (const agg of aggregations) {
    if (agg.estimatedVolume) {
      volumes.push(agg.estimatedVolume);
    }
    if (agg.estimatedTransactionCount) {
      txnCounts.push(agg.estimatedTransactionCount);
    }
    grossProfits.push(agg.grossProfit);
    grossCosts.push(agg.grossCost);
    grossRevenues.push(agg.grossRevenue);
  }
  const grossRevenue = getSumCurrencyValue({
    currencyValuesRaw: grossRevenues,
    quoteCurrency,
    pricingFlow,
  });
  const grossProfit = getSumCurrencyValue({
    currencyValuesRaw: grossProfits,
    quoteCurrency,
    pricingFlow,
  });
  const grossCost = getSumCurrencyValue({
    currencyValuesRaw: grossCosts,
    quoteCurrency,
    pricingFlow,
  });
  const estimatedVolume = getSumCurrencyValue({
    currencyValuesRaw: volumes,
    quoteCurrency,
    pricingFlow,
  });
  return {
    estimatedVolume,
    estimatedTransactionCount: getSumCount(txnCounts),
    grossRevenue,
    grossProfit,
    grossCost,
    profitMargin: {
      value: (grossProfit.value / grossRevenue.value) * 100, // multiply by 100 to get percentage like 13% instead of 0.13
      type: CurrencyValueType.PERCENT as const,
      concept: `Monthly gross profit / monthly gross revenue`,
      provenance: `${formatCurrencyValue(grossProfit)} / ${formatCurrencyValue(grossRevenue)}`,
    },
    takeRate: !isNil(estimatedVolume)
      ? {
          value: (100 * grossProfit.value) / estimatedVolume.value,
          type: CurrencyValueType.PERCENT as const,
          concept: 'Monthly gross profit / monthly estimated volume',
          provenance: `${formatCurrencyValue(grossProfit)} / ${formatCurrencyValue(estimatedVolume)}`,
        }
      : null,
  };
};

/**
 *
 * @param pricingFlow
 * @returns the same pricingFlow, but with all products' quotePrices and monthlySubscriptionFee converted to the quoteCurrency, using forex rates
 */
export function convertCurrencyValues(
  pricingFlow: AlpacaPricingFlow,
): AlpacaPricingFlow {
  const { additionalData } = pricingFlow;
  /////////////////////////
  //  3a: Convert products
  const newProducts = (pricingFlow.products ?? []).map((product) => {
    // 3a.1: Convert quotePrice

    let newQuoteCurrency = additionalData.quoteCurrency;
    // Special handle issuing products, because they will use the issuing entity's currency if the isShowLocalCurrencySelected is true
    if (
      product.categoryName === 'Issuing' &&
      additionalData.issuingConfig.isShowLocalCurrencySelected
    ) {
      const issuingEntityCurrency = additionalData.issuingConfig.entities.find(
        (entity) => entity.name === product.country,
      )?.issuingEntityCurrency;
      if (issuingEntityCurrency == null) {
        datadogRum.addError(
          `Could not find issuing entity for product ${product.id}`,
        );
      } else {
        newQuoteCurrency = issuingEntityCurrency;
      }
    }

    // 3a.2: Skip converting volumes, they are not CurrencyValues yet @TODO(CurrencyValueVolumes): Change products' volume once we convert volumes to CurrencyValue
    // 3a.3: Skip converting derived aggregations, this will happen elsewhere

    return {
      ...product,
      quotePrice: !isNil(product.quotePrice)
        ? convertQuotePrice(product.quotePrice, newQuoteCurrency, pricingFlow)
        : null,
      quoteCost:
        product.categoryName === 'Issuing' && !isNil(product.quoteCost)
          ? convertQuotePrice(product.quoteCost, newQuoteCurrency, pricingFlow)
          : null,
    };
  });

  ///////////////////////////
  // 3b: Convert additionalData
  // 3b.1: Convert monthly subscription fee
  const oldMonthlySubscriptionFee = additionalData.monthlySubscriptionFee;
  const newMonthlySubscriptionFee =
    oldMonthlySubscriptionFee == null
      ? oldMonthlySubscriptionFee
      : convertQuotePrice(
          oldMonthlySubscriptionFee,
          additionalData.quoteCurrency,
          pricingFlow,
        );
  // 3b.2: Convert issuingConfig
  const newIssuingConfig = makeIssuingConfigConsistent({ pricingFlow });

  return {
    ...pricingFlow,
    additionalData: {
      ...additionalData,
      monthlySubscriptionFee:
        newMonthlySubscriptionFee as MonthlySubscriptionFee,
      issuingConfig: newIssuingConfig,
    },
    products: newProducts as AlpacaProduct[],
  };
}

function makeIssuingConfigConsistent(props: {
  pricingFlow: AlpacaPricingFlow;
}): AlpacaIssuingConfig {
  const { pricingFlow } = props;
  const { issuingConfig } = pricingFlow.additionalData;
  if (issuingConfig == null) return issuingConfig;

  const newEntities = issuingConfig.entities.map((entity) => {
    const newIssuingEntityCurrency = issuingConfig.isShowLocalCurrencySelected
      ? entity.issuingEntityCurrency
      : pricingFlow.additionalData.quoteCurrency;
    const newAvgTransactionSize = convertCurrencyValueForex(
      entity.avgTransactionSize,
      newIssuingEntityCurrency,
      pricingFlow,
    );
    const newMonthlySpendAtScale = convertCurrencyValueForex(
      entity.monthlySpendAtScale,
      newIssuingEntityCurrency,
      pricingFlow,
    );
    return {
      ...entity,
      avgTransactionSize: newAvgTransactionSize,
      monthlySpendAtScale: newMonthlySpendAtScale,
    };
  });
  return { ...issuingConfig, entities: newEntities };
}

export async function makePricingFlowCurrencyValuesConsistent(props: {
  pricingFlow: AlpacaPricingFlow;
  updateFlow: (flow: AlpacaPricingFlow, showLoading: false) => void;
}): Promise<void> {
  console.log('CALL makePricingFlowCurrencyValuesConsistent');
  const { pricingFlow, updateFlow } = props;
  // Step 1: Find all necessary currency conversion pairs
  const currencyConversions = getCurrencyConversionsNeeded(pricingFlow);

  // Step 2: Get forex rates for these pairs
  const forexRates = await getForexRates(currencyConversions);
  const newPricingFlowWithForexRates = {
    ...pricingFlow,
    additionalData: { ...pricingFlow.additionalData, forexRates },
  };

  // Step 3: Convert all currency values (products, additionalData)
  const newPricingFlowWithNewCurrencyValues = convertCurrencyValues(
    newPricingFlowWithForexRates,
  );

  // Step 4: mark that we've done the conversion, so that if we later see
  // mismatches, we know that something is broken
  const newPricingFlow = {
    ...newPricingFlowWithNewCurrencyValues,
    additionalData: {
      ...newPricingFlowWithNewCurrencyValues.additionalData,
      shouldCurrencyValuesBeConsistent: true,
    },
  };
  updateFlow(newPricingFlow, false);
}

export function getCurrencyConversionsNeeded(
  pricingFlow: AlpacaPricingFlow,
  // If the pricing flow already has a quoteCurrency, this will be ignored.
  // However, at the beginning of the flow when you haven't constructed
  // additionalData yet, we need a default currency to construct pairs with.
  defaultCurrencyOverride?: AlpacaSupportedCurrency,
): {
  [key: string]: AlpacaSupportedCurrency[];
} {
  const currencyConversions: { [key: string]: AlpacaSupportedCurrency[] } = {};
  function addCurrencyPair(
    currency1: AlpacaSupportedCurrency,
    currency2: AlpacaSupportedCurrency,
  ) {
    if (
      currencyConversions[currency2] &&
      currencyConversions[currency2].includes(currency1)
    )
      return;
    if (!currencyConversions[currency2]) {
      currencyConversions[currency2] = [];
    }
    currencyConversions[currency2].push(currency1);
    if (!currencyConversions[currency2].includes(ALPACA_DEFAULT_CURRENCY)) {
      currencyConversions[currency2].push(ALPACA_DEFAULT_CURRENCY);
    }
  }

  // Add all currency pairs between the quote currency and anything else. Many
  // costs are represented in other currencies, so we will typically need most
  // of these
  for (const currency of ALPACA_SUPPORTED_CURRENCIES) {
    addCurrencyPair(
      currency,
      pricingFlow.additionalData?.quoteCurrency ??
        defaultCurrencyOverride ??
        ALPACA_DEFAULT_CURRENCY,
    );
  }
  ////////////////////////
  // a: Check products
  // a.1: Check quotePrice (against quoteCurrency or issuingEntityCurrency)
  // a.2: Skip checking volumes, they are not CurrencyValues yet
  // a.3: Skip checking derived aggregations, this will happen elsewhere
  (pricingFlow.products ?? []).forEach((product) => {
    if (product.quotePrice != null) {
      const currencies = getCurrenciesFromQuotePrice(product.quotePrice);
      if (product.categoryName === 'Issuing') {
        const entity = pricingFlow.additionalData.issuingConfig.entities.find(
          (entity) => entity.name === product.country,
        );
        if (entity == null) {
          datadogRum.addError(
            `Could not find issuing entity for product ${product.id}`,
          );
          return;
        }
        currencies.forEach((c) =>
          addCurrencyPair(c, entity.issuingEntityCurrency),
        );
      } else {
        currencies.forEach((c) =>
          addCurrencyPair(c, pricingFlow.additionalData.quoteCurrency),
        );
      }
    }
  });

  ////////////////////////
  // b: Check additionalData
  const { additionalData } = pricingFlow;
  if (additionalData == null) return currencyConversions;
  // b.1: Check monthly subscription fee (against quoteCurrency)
  if (additionalData.monthlySubscriptionFee != null) {
    const currencies = getCurrenciesFromQuotePrice(
      additionalData.monthlySubscriptionFee,
    );
    currencies.forEach((c) => addCurrencyPair(c, additionalData.quoteCurrency));
  }
  // b.2: Check issuingConfig (against quoteCurrency or issuingEntityCurrency)
  if (additionalData.issuingConfig != null) {
    additionalData.issuingConfig.entities.forEach((entity) => {
      const currencies = getCurrenciesFromQuotePrice(
        entity.avgTransactionSize,
      ).concat(getCurrenciesFromQuotePrice(entity.monthlySpendAtScale));
      currencies.forEach((c) =>
        addCurrencyPair(c, entity.issuingEntityCurrency),
      );
    });
  }

  return currencyConversions;
}

function getCurrenciesFromQuotePrice(
  quotePrice: QuotePrice,
): AlpacaSupportedCurrency[] {
  switch (quotePrice.type) {
    case CurrencyValueType.FLAT:
    case CurrencyValueType.FLAT_AND_PERCENT:
      return [quotePrice.currency];
    case CurrencyValueType.PERCENT:
      // case 'count':
      return [];
    case 'tiered':
      return quotePrice.tiers.flatMap((tier) =>
        getCurrenciesFromQuotePrice(tier.currencyValue),
      );
    case 'ramped':
      return quotePrice.rampValues.flatMap((value) =>
        getCurrenciesFromQuotePrice(value),
      );
    default:
      const typecheck: never = quotePrice;
      datadogRum.addError(`Unknown quote price type ${typecheck}`);
      return [];
  }
}

export async function getForexRates(currencyConversions: {
  [key: string]: AlpacaSupportedCurrency[];
}): Promise<ForexRates> {
  const forexRates: ForexRates = {};
  for (const fromCurrency in currencyConversions) {
    const toCurrencies = currencyConversions[fromCurrency];
    const forexRate = await api.post('forex_rates', {
      fromCurrency,
      toCurrencies,
    });
    forexRates[fromCurrency] = forexRate.data;
  }
  return forexRates;
}

/**
 *
 * @param quotePrice
 * @param checkCurrency
 * @returns true if all currencies on the quotePrice match the checkCurrency
 */
function checkQuotePriceCurrency(
  quotePrice: QuotePrice | Count,
  checkCurrency: AlpacaSupportedCurrency,
): boolean {
  switch (quotePrice.type) {
    case CurrencyValueType.FLAT:
    case CurrencyValueType.FLAT_AND_PERCENT:
      return quotePrice.currency === checkCurrency;
    case CurrencyValueType.PERCENT:
    case 'count':
      return true;
    case 'tiered':
      return quotePrice.tiers.every((tier) =>
        checkQuotePriceCurrency(tier.currencyValue, checkCurrency),
      );
    case 'ramped':
      return quotePrice.rampValues.every((value) =>
        checkQuotePriceCurrency(value, checkCurrency),
      );
    default:
      const typecheck: never = quotePrice;
      datadogRum.addError(`Unknown quote price type ${typecheck}`);
      return false;
  }
}

/**
 *
 * @param pricingFlow checks the consistency of the pricing flow's quoteCurrency with the currencies of the monthlySubscriptionFee and all products' quotePrices
 */
function checkDerivedAggregationsConsistency(
  derivedAggs: AlpacaDerivedAggregations,
  checkCurrency: AlpacaSupportedCurrency,
  prefix: string,
) {
  const misMatches: { [key: string]: any } = {};
  Object.entries(derivedAggs).forEach(([key, derivedValue]) => {
    const matched = isNil(derivedValue)
      ? true
      : checkQuotePriceCurrency(derivedValue, checkCurrency);
    if (!matched) {
      misMatches[`${prefix}_${key}`] = derivedValue;
    }
  });
  return misMatches;
}

export function checkPricingFlowConsistency(pricingFlow: AlpacaPricingFlow) {
  const additionalData = pricingFlow.additionalData;
  if (isNil(additionalData)) {
    return;
  }
  const quoteCurrency = additionalData.quoteCurrency;

  let misMatches: { [key: string]: any } = {};

  // Check monthly subscription fee currency
  if (
    additionalData.monthlySubscriptionFee != null &&
    !checkQuotePriceCurrency(
      additionalData.monthlySubscriptionFee,
      quoteCurrency,
    )
  ) {
    misMatches['monthlySubscriptionFee'] =
      additionalData.monthlySubscriptionFee;
  }

  // Check products' quotePrice currency
  (pricingFlow.products ?? []).forEach((product) => {
    // For issuing, if the additionalData.issuingConfig.isShowLocalCurrencySelected = true, check against the issuing product's entity's currency
    // otherwise, treat like all the other products (i.e. check against quoteCurrency)
    if (
      product.categoryName === 'Issuing' &&
      additionalData.issuingConfig.isShowLocalCurrencySelected
    ) {
      const entity = additionalData.issuingConfig.entities.find(
        (entity) => entity.name === product.country,
      );
      if (entity == null) {
        datadogRum.addError(
          `Could not find issuing entity for product ${product.id}`,
        );
        return;
      }
      if (
        product.quotePrice != null &&
        !checkQuotePriceCurrency(
          product.quotePrice,
          entity.issuingEntityCurrency,
        )
      ) {
        misMatches[`${product.id}_entity`] = entity.issuingEntityCurrency;
      }
    }
    // Check quotePrice matches quoteCurrency
    if (
      product.quotePrice != null &&
      !checkQuotePriceCurrency(product.quotePrice, quoteCurrency)
    ) {
      misMatches[product.id] = product.quotePrice;
    }
    // check products' derived aggregations
    if (product.derivedAggregations) {
      misMatches = {
        ...misMatches,
        ...checkDerivedAggregationsConsistency(
          product.derivedAggregations,
          quoteCurrency,
          `${product.id}_derived`,
        ),
      };
    }
  });

  // Check Issuing product category because it has some currency values on it
  const issuingConfig = additionalData.issuingConfig;
  issuingConfig.entities.forEach((issuingEntity) => {
    const currency = issuingConfig.isShowLocalCurrencySelected
      ? issuingEntity.issuingEntityCurrency
      : quoteCurrency;
    if (!checkQuotePriceCurrency(issuingEntity.avgTransactionSize, currency)) {
      misMatches[`${issuingEntity.name}_avgTransactionSize`] =
        issuingEntity.avgTransactionSize;
    }
    if (!checkQuotePriceCurrency(issuingEntity.monthlySpendAtScale, currency)) {
      misMatches[`${issuingEntity.name}_monthlySpendAtScale`] =
        issuingEntity.monthlySpendAtScale;
    }
  });

  // check categories' derived aggregations
  for (const category of additionalData.productCategories ?? []) {
    if (category.derivedAggregations) {
      misMatches = {
        ...misMatches,
        ...checkDerivedAggregationsConsistency(
          category.derivedAggregations,
          quoteCurrency,
          `pricing_flow_derived`,
        ),
      };
    }
  }
  // check pricing flow's top-level derived aggregations
  if (pricingFlow.additionalData.derivedAggregations) {
    misMatches = {
      ...misMatches,
      ...checkDerivedAggregationsConsistency(
        pricingFlow.additionalData.derivedAggregations,
        quoteCurrency,
        `pricing_flow_derived`,
      ),
    };
  }

  // @TODO(CurrencyValueVolumes): Change products' volume once we convert volumes to CurrencyValue
  if (Object.keys(misMatches).length > 0) {
    console.log('mismatches: ', misMatches);
    datadogRum.addError(
      `Currency mismatches in pricing flow with quoteCurrency: ${quoteCurrency}`,
      misMatches,
    );
  }
}

function getTransactionCount(
  product: AlpacaProduct,
  pricingFlow: AlpacaPricingFlow,
): number | null {
  switch (product.categoryName) {
    case 'Treasury FX':
    case 'Collections':
    case 'Global Accounts':
    case 'Payouts':
      return product.transactionCount ?? null;
    case 'Issuing':
      return getVolumeForIssuingProduct(product, pricingFlow).transactionCount
        .value;
  }
}

const computeDerivedAggregationsForProduct = (
  product: AlpacaProduct,
  pricingFlow: AlpacaPricingFlow,
): AlpacaDerivedAggregations => {
  const currency =
    product.categoryName === 'Issuing'
      ? currencyForIssuingProduct(product, pricingFlow)
      : pricingFlow.additionalData.quoteCurrency;
  const cost = getCost(
    product,
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
    pricingFlow.currentPricingCurves,
    currency,
    pricingFlow,
  );
  const quotePrice =
    product.quotePrice ??
    pricingFlow.currentPricingCurves[product.id]?.pricingInformation
      ?.listPrice ??
    ZERO_PERCENT;
  return computeDerivedAggregation({
    cost: cost,
    quotePrice: quotePrice,
    volume: getVolume(product, pricingFlow),
    numTransactions: getTransactionCount(product, pricingFlow),
    quoteCurrency: currency,
    pricingFlow,
  });
};

export const computeDerivedAggregationsForProducts = (
  products: AlpacaProduct[],
  pricingFlow: AlpacaPricingFlow,
  currency: AlpacaSupportedCurrency,
): AlpacaDerivedAggregations => {
  const aggs = products.map((product) => {
    return computeDerivedAggregationsForProduct(product, pricingFlow);
  });
  return combineAggregations(aggs, currency, pricingFlow);
};

export function computeGrossValue({
  quotePriceRaw,
  volumeRaw,
  numTransactions,
  quoteCurrency,
  pricingFlow,
}: {
  quotePriceRaw: QuotePrice;
  volumeRaw: CurrencyValueFlat | null;
  numTransactions: number | null;
  quoteCurrency: AlpacaSupportedCurrency;
  pricingFlow: AlpacaPricingFlow;
}): DerivedValue<CurrencyValueFlat> {
  const quotePrice = convertQuotePrice(
    quotePriceRaw,
    quoteCurrency,
    pricingFlow,
  );
  const volume = isNil(volumeRaw)
    ? null
    : (convertCurrencyValueForex(
        volumeRaw,
        quoteCurrency,
        pricingFlow,
      ) as CurrencyValueFlat);
  const { value, concept, provenance } = ((): {
    value: number;
    concept?: string | null;
    provenance: string;
  } => {
    switch (quotePrice.type) {
      case CurrencyValueType.FLAT:
        if (numTransactions !== null) {
          return {
            value: quotePrice.value * numTransactions,
            concept: 'Quote price * number of transactions',
            provenance: `${formatCurrencyValue(quotePrice)} * ${numTransactions}`,
          };
        }
        return { value: 0, provenance: `missing num transactions` };
      case CurrencyValueType.PERCENT:
        if (volume !== null) {
          return {
            // ##AlpacaPercentsX100
            // In Alpaca, a number like 1.23% is stored as 1.23, not as 0.0123.
            // Therefore, when using the percent in a calculation, it needs to
            // be divided by 100
            value: (quotePrice.value * volume.value) / 100,
            concept: 'Quote price * monthly volume',
            provenance: `${formatCurrencyValue(quotePrice)} * ${formatCurrencyValue(volume)}`,
          };
        }
        return { value: 0, provenance: `missing volume` };
      case CurrencyValueType.FLAT_AND_PERCENT:
        if (volume === null && numTransactions === null) {
          return {
            value: 0,
            provenance: `missing volume or num transactions`,
          };
        }
        return {
          value:
            quotePrice.flat * (numTransactions ?? 0) +
            // #AlpacaPercentsX100
            (quotePrice.percent * (volume?.value ?? 0)) / 100,
          concept: 'Quote price * monthly volume',
          provenance: `$${round(quotePrice.flat, 2)} * ${numTransactions} + ${round(quotePrice.percent, 2)}% * ${formatCurrencyValue(volume)}`,
        };
      case 'tiered':
        // Intuitively, you might think to calculate this via a waterfall,
        // attributing different slices of the volume to different pricing
        // tiers. However, this is NOT how it works (we think?).
        // Instead, you just take the tier that matches the provided estimated
        // volume and use that price
        // #AlpacaTierAggregationCalculations
        const tiers = sortTiers(quotePrice.tiers);
        if (tiers.length === 0) {
          return {
            value: 0,
            provenance: `empty tiers`,
          };
        }
        let prevTier = tiers[0];
        for (const tier of tiers) {
          if (tier.minimum.type === 'count') {
            if (
              !isNil(numTransactions) &&
              tier.minimum.value > numTransactions
            ) {
              return computeGrossValue({
                quotePriceRaw: prevTier.currencyValue,
                volumeRaw: volume,
                numTransactions,
                quoteCurrency,
                pricingFlow,
              });
            }
          } else {
            if (!isNil(volume) && tier.minimum.value > volume.value) {
              return computeGrossValue({
                quotePriceRaw: prevTier.currencyValue,
                volumeRaw: volume,
                numTransactions,
                quoteCurrency,
                pricingFlow,
              });
            }
          }
          prevTier = tier;
        }
        // If either:
        // - the tier minimum type (txn count or volume) does not match the
        //   estimated volume type (txn count or volume)
        // - the estimated volume is larger than the largest minimum
        // just use the last tier for pricing
        return computeGrossValue({
          quotePriceRaw: tiers[tiers.length - 1].currencyValue,
          volumeRaw: volume,
          numTransactions,
          quoteCurrency,
          pricingFlow,
        });
      case 'ramped':
        throw new Error(`Alpaca does not support ramped quote prices yet!`);
      default:
        const typecheck: never = quotePrice;
        throw new Error(`bad quote price type ${typecheck}`);
    }
  })();
  return {
    value,
    concept,
    provenance,
    type: CurrencyValueType.FLAT,
    currency: quoteCurrency,
  };
}
function computeDerivedAggregation({
  cost,
  quotePrice,
  volume,
  numTransactions,
  quoteCurrency,
  pricingFlow,
}: {
  cost: QuotePrice;
  quotePrice: QuotePrice;
  volume: CurrencyValueFlat | null;
  numTransactions: number | null;
  quoteCurrency: AlpacaSupportedCurrency;
  pricingFlow: AlpacaPricingFlow;
}): AlpacaDerivedAggregations {
  const grossRevenue = computeGrossValue({
    quotePriceRaw: quotePrice,
    volumeRaw: volume,
    numTransactions,
    quoteCurrency,
    pricingFlow,
  });
  const grossCost = computeGrossValue({
    quotePriceRaw: cost,
    volumeRaw: volume,
    numTransactions,
    quoteCurrency,
    pricingFlow,
  });
  const grossProfit = {
    value: grossRevenue.value - grossCost.value,
    type: CurrencyValueType.FLAT as const,
    currency: quoteCurrency,
    concept: 'Monthly gross revenue - monthly gross cost',
    provenance: `${formatCurrencyValue(grossRevenue)} - ${formatCurrencyValue(grossCost)}`,
  };
  const profitMargin = {
    value: (grossProfit.value / grossRevenue.value) * 100, // multiply by 100 to get percentage like 13% instead of 0.13
    type: CurrencyValueType.PERCENT as const,
    concept: 'Monthly gross profit / monthly gross revenue',
    provenance: `${formatCurrencyValue(grossProfit)} / ${formatCurrencyValue(grossRevenue)}`,
  };
  const takeRate = !isNil(volume)
    ? {
        // #AlpacaPercentsX100
        value: (100 * grossProfit.value) / volume.value,
        type: CurrencyValueType.PERCENT as const,
        concept: 'Monthly gross profit / monthly volume',
        provenance: `${formatCurrencyValue(grossProfit)} / ${formatCurrencyValue(volume)}`,
      }
    : null;
  const estimatedVolume = isNil(volume)
    ? null
    : {
        type: CurrencyValueType.FLAT as const,
        value: volume.value,
        currency: quoteCurrency,
        provenance: 'itself',
      };
  const estimatedTransactionCount = isNil(numTransactions)
    ? null
    : {
        type: 'count' as const,
        value: numTransactions,
        provenance: 'itself',
      };
  return {
    grossRevenue,
    grossCost,
    grossProfit,
    profitMargin,
    takeRate,
    estimatedVolume,
    estimatedTransactionCount,
  };
}

export function addAllDerivedAggregationsToPricingFlow(
  pricingFlow: AlpacaPricingFlow,
) {
  setAutoFreeze(false);
  const newPricingFlow = produce(pricingFlow, (draftPricingFlow) => {
    if (!draftPricingFlow.additionalData) {
      return;
    }
    draftPricingFlow.additionalData.derivedAggregations =
      computeDerivedAggregationsForQuote(draftPricingFlow);
    /**
     * aggregations per category
     */
    if (draftPricingFlow.additionalData.productCategories) {
      draftPricingFlow.additionalData.productCategories.forEach(
        (c) =>
          (c.derivedAggregations = computeDerivedAggregationsForCategory(
            c.category,
            draftPricingFlow,
          )),
      );
    }
    /**
     * aggregations per product
     */
    if (draftPricingFlow.products) {
      draftPricingFlow.products.forEach((p) => {
        p.derivedAggregations = computeDerivedAggregationsForProduct(
          p,
          draftPricingFlow,
        );
      });
    }
  });
  return newPricingFlow;
}
